import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Capacitor } from "@capacitor/core";

export const useAnalytics = () => {
  const page = async (path) => {
    if (Capacitor.getPlatform() !== "web") {
      try {
        await FirebaseAnalytics.setScreenName({
          screenName: path,
        });
      } catch (e) {
        console.error("error while logging analytics page", path);
        console.error(e);
      }
    }
  };
  const event = async (name, params) => {
    if (Capacitor.getPlatform() !== "web") {
      try {
        await FirebaseAnalytics.logEvent({
          name,
          params,
        });
      } catch (e) {
        console.error("error while logging analytics event", name, params);
        console.error(e);
      }
    }
  };
  return {
    event,
    page,
  };
};
