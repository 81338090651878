import { defineStore } from "pinia";
import { useStrapi } from "../composable/useStrapi";
import { useMeasures } from "@/store/useMeasures.js";

export const useAdditions = defineStore("additions", {
  state: () => ({
    lists: {},
    water_changes: {},
    config: null,
    setting: null,
  }),

  getters: {},
  actions: {
    reset() {
      this.lists = {};
      this.water_changes = {};
    },
    async getAddition(aquariumId, additionId) {
      await this.loadAddition(aquariumId, additionId);
      return (
        this.lists[aquariumId] &&
        this.lists[aquariumId].find((m) => m.id == additionId)
      );
    },
    async getWaterChange(aquariumId, additionId) {
      await this.loadAddition(aquariumId, additionId);
      return (
        this.water_changes[aquariumId] &&
        this.water_changes[aquariumId].find((m) => m.id == additionId)
      );
    },
    async reloadAddition(aquariumId, additionId) {
      await this.loadAddition(aquariumId, additionId, true);
      return;
    },
    async loadAddition(aquariumId, additionId, force = false) {
      if (!this.lists[aquariumId] || this.lists[aquariumId].updated || force) {
        try {
          const { findOne } = useStrapi();
          const ent = await findOne("/additions", additionId, {
            populate: ["aquarium", "product", "icp"],
          });
          const res = ent.data?.attributes;
          res.id = ent.data?.id;

          if (res.icp) {
            res.icp = {
              ...(res.icp?.data?.attributes || {}),
              id: res.icp?.data?.id,
            };
          }

          if (res.aquarium?.data?.id != aquariumId) {
            console.log(
              "Addition",
              additionId,
              "is accessed for aquarium",
              aquariumId,
              "but it appears to come from",
              res.aquarium?.data?.id,
              "-> Fail to retrieve"
            );
            return;
          }

          delete res.aquarium;

          if (res.element === "sal") {
            if (!this.water_changes[aquariumId]) {
              this.water_changes[aquariumId] = [];
            }

            const alreadyIdx = this.water_changes[aquariumId].findIndex(
              (e) => e.id == additionId
            );
            if (alreadyIdx === -1) {
              this.water_changes[aquariumId].push(res);
            } else {
              this.water_changes[aquariumId][alreadyIdx] = res;
            }

            this.water_changes[aquariumId].sort(
              (a, b) =>
                (b.date ? new Date(b.date).getTime() : 0) -
                (a.date ? new Date(a.date).getTime() : 0)
            );
          } else {
            if (!this.lists[aquariumId]) {
              this.lists[aquariumId] = [];
            }

            const alreadyIdx = this.lists[aquariumId].findIndex(
              (e) => e.id == additionId
            );
            if (alreadyIdx === -1) {
              this.lists[aquariumId].push(res);
            } else {
              this.lists[aquariumId][alreadyIdx] = res;
            }

            this.lists[aquariumId].sort(
              (a, b) =>
                (b.date ? new Date(b.date).getTime() : 0) -
                (a.date ? new Date(a.date).getTime() : 0)
            );
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    async loadAdditions(aquariumId, force /*, next = false*/) {
      // TODO load next
      // TODO check sort is ok
      if (!this.lists[aquariumId] || this.lists[aquariumId].updated || force) {
        try {
          const { client } = useStrapi();
          const res = await client("/additions", {
            params: {
              filters: { aquarium: aquariumId },
              pagination: { pageSize: 100, withCount: true },
              populate: ["icp"],
              sort: { date_end: "desc" },
            },
          });
          if (!this.lists[aquariumId]) {
            this.lists[aquariumId] = [];
          }
          if (!this.water_changes[aquariumId]) {
            this.water_changes[aquariumId] = [];
          }
          this.lists[aquariumId] = res.additions.data.map((ent) => {
            const toAdd = ent.attributes;
            toAdd.id = ent.id;

            if (toAdd.icp) {
              toAdd.icp = {
                ...(toAdd.icp?.data?.attributes || {}),
                id: toAdd.icp?.data?.id,
              };
            }
            return toAdd;
          });
          // sort again because there may be easure that was there before push
          this.lists[aquariumId].sort(
            (a, b) =>
              (b.date_end ? new Date(b.date_end).getTime() : 0) -
              (a.date_end ? new Date(a.date_end).getTime() : 0)
          );
          this.lists[aquariumId].updated = false;

          this.water_changes[aquariumId] = res.water_changes.data.map((ent) => {
            const toAdd = ent.attributes;
            toAdd.id = ent.id;

            return toAdd;
          });
          // sort again because there may be easure that was there before push
          this.water_changes[aquariumId].sort((a, b) => {
            const adate =
                (a.data?.additions &&
                  a.data?.additions[0] &&
                  a.data?.additions[0].date) ||
                a.date_end,
              bdate =
                (b.data?.additions &&
                  b.data?.additions[0] &&
                  b.data?.additions[0].date) ||
                b.date_end;
            return (
              (bdate ? new Date(bdate).getTime() : 0) -
              (adate ? new Date(adate).getTime() : 0)
            );
          });

          const measures = useMeasures();
          await measures.matchAdditions(aquariumId, this.lists[aquariumId]);
        } catch (e) {
          console.log(e);
        }
      }
    },
    async create(data) {
      try {
        const { create } = useStrapi();
        const res = await create("additions", {
          data,
        });
        if (this.lists[data.aquarium]) {
          this.lists[data.aquarium].updated = true;
        }
        return res;
      } catch (e) {
        console.log(e);
      }
    },
    async update(id, data) {
      try {
        const { update } = useStrapi();
        const res = await update("additions", id, {
          data,
        });
        if (this.lists[data.aquarium]) {
          this.lists[data.aquarium].updated = true;
        }
        return res;
      } catch (e) {
        console.log(e);
      }
    },
    async toggleFromTask(task, aquariumId, checked) {
      const add = await this.getAddition(aquariumId, task.ref_id);
      if (add) {
        // find addition part matching task
        const idx = add.data.additions.findIndex(
          (p) => p.date === task.params.addition.date
        );
        if (idx > -1) {
          const toUpdate = { data: add.data };
          toUpdate.data.additions[idx].done = checked;
          await this.update(add.id, toUpdate, true);
          await this.loadAdditions(aquariumId, true);
        }
      }
    },
    async delete(aquariumId, add) {
      try {
        const { delete: remove } = useStrapi();
        const res = await remove("additions", add.id);

        const measures = useMeasures();
        await measures.removeAddition(aquariumId, add);

        return res;
      } catch (e) {
        console.log(e);
      }
    },
  },
});
