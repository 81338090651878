<template>
  <div>
    <ion-radio-group
      :value="modelValue"
      :modelValue="props.modelValue"
      @ionChange="update($event)"
      class="flex items-center gap-3"
    >
      <template v-for="ent of options" :key="ent.value">
        <div class="flex items-center justify-center gap-1">
          <ion-radio :value="ent.value" labelPlacement="end" mode="md">
          </ion-radio>
          <label
            :for="ent.value"
            @click="update({ detail: { value: ent.value } })"
          >
            {{ ent.label }}
          </label>
        </div>
      </template>
    </ion-radio-group>
    <div
      v-if="vErrors && vErrors.length > 0"
      class="text-red-500 text-xs text-left pl-4 pt-1"
    >
      <div class="input-errors" v-for="error of vErrors" :key="error.$uid">
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { IonRadioGroup, IonRadio } from "@ionic/vue";

const props = defineProps({
  modelValue: String,
  options: Array,
  label: String,
  name: String,
  validator: Object,
});

const emit = defineEmits(["update:modelValue"]);
const update = ($event) => {
  emit("update:modelValue", $event.detail.value);
};

const fvalid = computed(() => props.validator && props.validator[props.name]);
const vErrors = computed(() => {
  return fvalid.value?.$errors;
});
</script>
<style>
ion-radio {
  --color: transparent;
  --color-checked: #16db8f;
}
ion-radio.radio-checked.md::part(container) {
  border-color: transparent;
}
ion-radio.md::part(container) {
  background-color: #0e153b;
}
</style>
