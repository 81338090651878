import { useStrapi } from "@/composable/useStrapi";

export const useSavePictures = () => {
  const sortMedias = (object, entityField, order) => {
    if (object && object[entityField] && object[entityField].data && order) {
      object[entityField].data.sort((a, b) => {
        return order.indexOf(a.id) - order.indexOf(b.id);
      });
    } else if (
      object &&
      object[entityField] &&
      object[entityField].length > 0 &&
      order
    ) {
      object[entityField].sort((a, b) => {
        return order.indexOf(a.id) - order.indexOf(b.id);
      });
    }
  };
  const mediaUpload = async (
    files,
    entityPluralized,
    entityField,
    entityId
  ) => {
    try {
      const { client } = useStrapi();

      let hasPics = false;
      const formData = new FormData();
      for (const image of files) {
        if (image.toUpload) {
          hasPics = true;
          const fimage = await fetch(image.webPath).then((r) => r.blob());
          formData.append(
            "files." + entityField,
            new File([fimage], image.filename, { type: fimage.type })
          );
        }
      }
      formData.append("data", JSON.stringify({}));
      if (hasPics) {
        const res = await client(`/${entityPluralized}/${entityId}`, {
          method: "PUT",
          data: formData,
        });
        return res;
      }

      return [];
    } catch (e) {
      console.error(e);
      return { error: true };
    }
  };

  const removeMedia = async (id, entityPluralized, entityField, entityId) => {
    try {
      const { client } = useStrapi();
      //console.log('remove media')
      const res = await client(`/fileupdate/${id}/remove`, {
        method: "POST",
        data: {
          fileModel: entityPluralized,
          fileField: entityField,
          entityId,
        },
      });
      return res;
    } catch (e) {
      console.error(e);
      return { error: true };
    }
  };

  const saveMedias = async (
    entityPluralized,
    entityField,
    entityId,
    newValue,
    oldValue,
    { doNotRemove, progress, multiple } = {}
  ) => {
    const listOfUsedMediasIds = [];
    let hasNewImages = false;

    //console.log("saveMedias", newValue);

    const treatValue = async (f, i = 0) => {
      if (f.toUpload) {
        const nf = await mediaUpload(
          [f],
          entityPluralized,
          entityField,
          entityId
        );
        //console.log("return after mediaUpload", nf);
        hasNewImages = true;
      } else if (f.id) {
        //console.log("Push to listOfUsed when treating file", f.id);
        listOfUsedMediasIds.push(f.id);
      }
    };

    if (multiple) {
      if (newValue != null && newValue.length > 0) {
        for (let i = 0; i < newValue.length; i++) {
          const f = newValue[i];
          await treatValue(f, i);
        }
      }
    } else {
      if (newValue) {
        await treatValue(newValue);
      }
    }

    let medias = [];
    if (hasNewImages) {
      const { client } = useStrapi();
      const newEntity = await client(`${entityPluralized}/${entityId}`, {
        method: "GET",
        params: { populate: [entityField] },
      });
      medias =
        newEntity?.data?.attributes &&
        newEntity.data.attributes[entityField]?.data;
      //console.log("get medias", medias);
      if (multiple) {
        // map media ids
        for (const m of medias) {
          const upname = m.attributes.name;
          let hasBeenJustUploaded = false;
          if (multiple) {
            const ent = newValue.find(
              (nv) => nv.filename === upname && nv.toUpload
            );
            if (ent) {
              hasBeenJustUploaded = true;
            }
          } else {
            if (newValue.filename === upname && newValue.toUpload) {
              hasBeenJustUploaded = true;
            }
          }

          if (
            // check if it's a real new entry not to be removed
            hasBeenJustUploaded
          ) {
            //console.log("Push to listOfUsed when setting id", m.id);
            listOfUsedMediasIds.push(m.id);
          }
        }
      }
    }

    //console.log("oldValue", oldValue, "listOfUsed", listOfUsedMediasIds);
    if (!doNotRemove) {
      if (multiple && oldValue?.length > 0) {
        for (const m of oldValue) {
          if (!listOfUsedMediasIds.includes(m.id)) {
            //console.log("remove media, ", m.id);
            await removeMedia(m.id, entityPluralized, entityField, entityId);
          }
        }
      } else if (!multiple && oldValue != null) {
        // do not remove media if not multiple.... (for now, if there is a use case, think about it, we need to check if new media is differreent from previous one)
        //await removeMedia(oldValue.id, entityPluralized, entityField, entityId);
      }
    }

    const medias_order = [];
    const addMediaToOrder = (def) => {
      if (def.toUpload) {
        // image has been uploaded previously and retrieved in medias array
        // we should find it by name (def.filename === media.name)
        const ent = medias.find(
          (m) => def.filename === m.attributes?.name && def.toUpload
        );
        if (ent) {
          medias_order.push(def.id);
        }
      } else if (def.id) {
        medias_order.push(def.id);
      } else {
        console.error(
          "media has no id and has not been just uploaded => should not happen"
        );
      }
    };
    if (multiple) {
      if (newValue != null && newValue.length > 0) {
        for (let i = 0; i < newValue.length; i++) {
          const f = newValue[i];
          addMediaToOrder(f);
        }
      }
    } else {
      if (newValue) {
        addMediaToOrder(newValue);
      }
    }

    return {
      medias_order,
    };
  };
  return {
    saveMedias,
    sortMedias,
  };
};
