import { defineStore } from "pinia";
import { useStrapi } from "../composable/useStrapi";
import { useSettings } from "@/store/useSettings.js";
import { useCategories } from "@/store/useCategories.js";
import { useNotifications } from "@/store/useNotifications.js";
import { useAdditions } from "@/store/useAdditions.js";
import { useEntries } from "@/store/useEntries.js";
import dayjs from "dayjs";

export const useTasks = defineStore("tasks", {
  state: () => ({
    lists: {},
    config: null,
    setting: null,
    updated: true,
  }),

  getters: {},
  actions: {
    reset() {
      this.lists = {};
      this.updated = true;
    },

    async loadConfig() {
      if (this.config == null) {
        const settings = useSettings();
        const entries = useEntries();

        if (settings.settings && settings.settings["tasks"]) {
          this.setting = settings.settings["tasks"];
          const catId = this.setting.category;
          if (catId) {
            if (catId) {
              const lst =
                (entries.entries[catId] && entries.entries[catId].all?.list) ||
                [];

              this.config = lst.map((entry) => {
                const res = {};
                Object.keys(this.setting).forEach((k) => {
                  if (
                    entry.values != null &&
                    entry.values[this.setting[k]] != null
                  ) {
                    res[k] = entry.values[this.setting[k]];
                  }
                });
                return res;
              });
              /*const order = this.setting?.tasks_order;
              this.config.sort((a, b) => {
                return order.indexOf(a.id) - order.indexOf(b.id);
              });*/
            }
          }
        }
      }
    },

    async getTask(aquariumId, taskId) {
      await this.loadTask(aquariumId, taskId);
      return (
        this.lists[aquariumId] &&
        this.lists[aquariumId].find((m) => m.id == taskId)
      );
    },
    async reloadTask(aquariumId, taskId) {
      await this.loadTask(aquariumId, taskId, true);
      return;
    },
    async loadTask(aquariumId, taskId, force = false) {
      if (this.updated || force) {
        try {
          const { findOne } = useStrapi();
          const ent = await findOne("/tasks", taskId, {
            populate: ["aquarium", "origin"],
          });
          if (ent) {
            const res = ent.data?.attributes;
            res.id = ent.data?.id;

            if (res.aquarium?.data?.id != aquariumId) {
              console.log(
                "Task",
                taskId,
                "is accessed for aquarium",
                aquariumId,
                "but it appears to come from",
                res.aquarium?.data?.id,
                "-> Fail to retrieve"
              );
              return;
            }

            delete res.aquarium;

            if (!this.lists[aquariumId]) {
              this.lists[aquariumId] = [];
            }

            const alreadyIdx = this.lists[aquariumId].findIndex((e) => {
              return e.id == taskId;
            });
            if (alreadyIdx === -1) {
              this.lists[aquariumId].push(res);
            } else {
              this.lists[aquariumId][alreadyIdx] = res;
            }

            this.lists[aquariumId].sort(
              (a, b) =>
                (b.date ? new Date(b.date).getTime() : 0) -
                (a.date ? new Date(a.date).getTime() : 0)
            );
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    async loadTasks(aquariumId, force = false /*, next = false*/) {
      // TODO load next
      // TODO check sort is ok
      //console.log("loadTasks", this.updated, afterDelete);
      if (this.updated || force) {
        try {
          const { client } = useStrapi();
          //const maxdate = dayjs().add(1, "week");
          // Should do something about that, at least make the wsort work, and get only 100 and get more if none is treated
          const res = await client("/tasks", {
            params: {
              filters: {
                aquarium: aquariumId,
                //date: { $lt: maxdate.toDate() },
              },
              populate: ["origin"],
              pagination: { pageSize: 100, withCount: true },
              sort: { date: "desc" },
            },
          });
          if (force) {
            //console.log("reset");
            this.lists[aquariumId] = [];
          }
          if (!this.lists[aquariumId]) {
            this.lists[aquariumId] = [];
          }
          this.lists[aquariumId] = res.data.map((ent) => {
            const toAdd = ent.attributes;
            toAdd.id = ent.id;

            return toAdd;
          });

          this.lists[aquariumId].sort(
            (a, b) =>
              (b.date ? new Date(b.date).getTime() : 0) -
              (a.date ? new Date(a.date).getTime() : 0)
          );
          this.updated = false;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async create(data) {
      try {
        const { create } = useStrapi();
        const res = await create("tasks", {
          data,
        });
        this.updated = true;
        return res;
      } catch (e) {
        console.log(e);
      }
    },
    async update(id, data) {
      try {
        const { update } = useStrapi();
        const res = await update("tasks", id, {
          data,
        });
        this.updated = true;
        return res;
      } catch (e) {
        console.log(e);
      }
    },
    async delete(id) {
      try {
        const { delete: remove } = useStrapi();
        const res = await remove("tasks", id);
        this.updated = true;
        return res;
      } catch (e) {
        console.log(e);
      }
    },
    async deleteMultiple(id) {
      try {
        const { client } = useStrapi();
        const res = await client("/task/removeMultiple/" + id, {
          method: "delete",
        });
        this.updated = true;
        return res;
      } catch (e) {
        console.log(e);
      }
    },
    markChange() {
      this.updated = true;
    },

    async toggle(task, aqId) {
      if (task.date_done == null) {
        await this.update(task.id, { date_done: dayjs.utc().toDate() });
        // update notifications linked to task
        const notifications = useNotifications();
        await notifications.readTypeRef("TASK", task.id);
        // if task is an addition, check addition as done
        if (task.ref_type === "ADDITION") {
          const additions = useAdditions();
          await additions.toggleFromTask(task, aqId, true);
        }
      } else {
        if (dayjs().diff(task.date_done, "minute") <= 10) {
          await this.update(task.id, { date_done: null });
          // if task is an addition, uncheck addition as done
          if (task.ref_type === "ADDITION") {
            const additions = useAdditions();
            await additions.toggleFromTask(task, aqId, false);
          }
        }
      }
      await this.loadTasks(aqId, true);
    },
  },
});
