<template>
  <div>
    <Swiper
      class="w-full below-pagination"
      grab-cursor
      :modules="modules"
      update-on-window-resize
      :pagination="{
        clickable: true,
        dynamicBullets: true,
        dynamicMainBullets: 10,
      }"
      :autoplay="{
        delay: 4000,
      }"
      @swiper="init"
    >
      <swiper-slide
        v-for="(img, idx) of pictures"
        :key="img.data || idx"
        class=""
      >
        <div
          class="w-full rounded overflow-hidden flex items-center justify-center cursor-pointer hover:opacity-90 scale-bg-hover transition-all duration-200"
          :style="{
            'aspect-ratio': props.aspectRatio,
            'background-image':
              'url(' +
              (img?.attributes
                ? img.attributes.formats?.small?.url || img.attributes?.url
                : img?.formats?.small?.url || img?.url) +
              ')',
          }"
          @click="() => open(idx)"
        ></div>
      </swiper-slide>
    </Swiper>
    <ion-modal :is-open="isOpen" @didDismiss="isOpen = false">
      <blue-content>
        <AquariumHeader
          :label="props.label || $t('collection.photos')"
          :subtitle="props.subtitle"
          :back-call="() => (isOpen = false)"
          v-if="!props.portal"
        />
        <div class="absolute float right-3 top-2 cursor-pointer" v-else>
          <ion-icon
            style="width: 28px; height: 28px"
            :icon="$icons.closeCircleOutline"
            @click="() => (isOpen = false)"
          ></ion-icon>
        </div>
        <div class="gallery-container m-auto" ref="galleryEl">
          <a
            v-for="img of pictures"
            :key="img.data"
            :data-pswp-width="img.width"
            :data-pswp-height="img.height"
            :data-pswp-src="img.attributes?.url || img?.url"
            class="gallery-item"
            :style="{
              width:
                (img.attributes?.formats?.small?.width ||
                  img?.formats?.small?.width) + 'px',
              height:
                (img.attributes?.formats?.small?.height ||
                  img?.formats?.small?.height) + 'px',
            }"
          >
            <img
              class="img-responsive"
              :src="
                img?.attributes
                  ? img.attributes.formats?.small?.url || img.attributes?.url
                  : img?.formats?.small?.url || img?.url
              "
            />
          </a>
        </div>
      </blue-content>
    </ion-modal>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";
import AquariumHeader from "@/components/aquarium/AquariumHeader.vue";
import "swiper/css";
import "swiper/css/pagination";

import { JustifiedGrid } from "@egjs/grid";
import "@ionic/vue/css/ionic-swiper.css";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, A11y, Autoplay } from "swiper";

import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";

const props = defineProps({
  pictures: Array,
  label: String,
  subtitle: String,
  noAnimations: Boolean,
  imageId: String,
  portal: Boolean,
  aspectRatio: {
    type: Number,
    default: 16 / 9,
  },
});

const isOpen = ref(false);

const swiper = ref();
const modules = [Pagination, A11y, Autoplay];
const galleryEl = ref();

function init(instance) {
  swiper.value = instance;
  if (currentToIdx.value && instance) {
    instance.slideTo(currentToIdx.value);
  }
}
const currentToIdx = ref(null);
watch(
  () => [props.imageId, props.pictures],
  ([n, pics]) => {
    const idx = pics.findIndex((p) => p.id == n);
    if (idx > -1) {
      currentToIdx.value = idx;
      if (swiper.value) {
        swiper.value.slideTo(currentToIdx.value);
      }
    }
  },
  { immediate: true }
);

const open = (idx) => {
  isOpen.value = true;
  currentToIdx.value = idx;
  setTimeout(() => {
    if (galleryEl.value) {
      const grid = new JustifiedGrid(galleryEl.value, {
        gap: 5,
        useResizeObserver: true,
        columnRange: [1, 3],
        observeChildren: true,
      });

      grid.renderItems();

      const options = {
        gallery: galleryEl.value,
        children: "a",
        pswpModule: () => import("photoswipe"),
      };
      if (props.noAnimations) {
        Object.assign(options, {
          showHideAnimationType: "none",
          zoomAnimationDuration: false,
          showAnimationDuration: 0,
          hideAnimationDuration: 0,
        });
      }
      const lightbox = new PhotoSwipeLightbox(options);
      if (props.noAnimations) {
        lightbox.addFilter(
          "useContentPlaceholder",
          (useContentPlaceholder, content) => {
            return false;
          }
        );
      }
      lightbox.init();
    }
  }, 100);
};
</script>

<style lang="css" scoped>
.gallery-item {
  display: block;
}

.gallery-item img {
  /*display: inline-block;*/
}
</style>
<style>
.pswp__top-bar {
  top: 40px !important;
}
.pswp img {
  object-fit: contain;
}
</style>
