import axios from "axios";
import { stringify } from "qs";
import { ref } from "vue";
import { Device } from "@capacitor/device";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";

//import { useCookies } from "vue3-cookies";
import { useStorage, initStorage } from "./useStorage";
import { useGlassfy } from "./useGlassfy";

import { useAquariums } from "@/store/useAquariums.js";
import { useTasks } from "@/store/useTasks.js";
import { useMeasures } from "@/store/useMeasures.js";
import { Capacitor } from "@capacitor/core";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

const STRAPI_URL = process.env.VUE_APP_STRAPI_URL || "http://localhost:1337"; // "https://api.bluereef.app"; // "https://strapi.local.com" //"http://192.168.1.12:1337" //|| "http://127.0.0.1:1337" || "http://localhost:1337";
const STRAPI_PREFIX = "/api";
//const STRAPI_COOKIE = "blue_jwt";

const token = ref();
const user = ref();
const favorites = ref({});
const dailyInfos = ref();
const reefers = ref([]);
const curDotation = ref();
const blocked_users = ref([]);
const blocked_by_users = ref([]);
const trialDuration = ref(3);
const localPreferences = ref();
const classifiedAdsParams = ref({ allowed: false });

export const useStrapi = () => {
  const baseURL = `${STRAPI_URL}${STRAPI_PREFIX}`;

  const initToken = async () => {
    await initStorage();
    const store = useStorage();
    const jwt = store.token;
    token.value = jwt;
  };

  const setToken = (t) => {
    token.value = t;
    const store = useStorage();
    if (t != null) {
      store.setToken(t);
    } else {
      store.setToken(t);

      // reset user
      user.value = null;
      favorites.value = {};
      dailyInfos.value = null;
      curDotation.value = null;
      blocked_users.value = [];
      blocked_by_users.value = [];
      classifiedAdsParams.value = { allowed: false };

      // reinit subscription manager
      if (Capacitor.isNativePlatform()) {
        try {
          const { connectUser } = useGlassfy();
          connectUser();
        } catch (e) {
          console.error(e);
          console.error("Error while connecting user to glassfy");
        }
      }

      // empty stores from account linked data
      const aquariums = useAquariums();
      const tasks = useTasks();
      const measures = useMeasures();
      aquariums.reset();
      tasks.reset();
      measures.reset();
    }
  };

  const client = async (url, fetchOptions) => {
    const headers = {};

    if (token.value && token.value) {
      headers.Authorization = `Bearer ${token.value}`;
    }

    // Map params according to strapi v3 and v4 formats
    if (fetchOptions?.params) {
      const params = stringify(fetchOptions.params, { encodeValuesOnly: true });
      if (params) {
        url = `${url}?${params}`;
      }
      delete fetchOptions.params;
    }

    const res = await axios.request({
      retry: 0,
      baseURL,
      url,
      ...(fetchOptions || {}),
      headers: {
        ...headers,
        ...(fetchOptions?.headers || {}),
      },
    });
    return res.data;
  };
  const setNotificationToken = async (token) => {
    try {
      const info = await Device.getInfo();
      const idData = await Device.getId();

      const { permissions } = useGlassfy();
      if (permissions.value) {
        info.installationId = permissions.installationId;
        info.subscriberId = permissions.subscriberId;
      }

      const store = useStorage();
      info.bubbles = store.bubble;

      await client("/users/notification_token", {
        method: "POST",
        data: { token, info, id: idData?.identifier },
      });
    } catch (e) {
      console.error(e);
    }
  };
  const updateRecentReefers = (lst) => {
    reefers.value = lst;
  };
  const fetchUser = async (force = false) => {
    if (token.value && (!user.value || force)) {
      try {
        const res = await client("/users/app/me", {
          params: {},
        });
        if (res) {
          user.value = res.user;
          favorites.value = res.favorites;
          dailyInfos.value = res.dailyInfos;
          reefers.value = res.last_reefers;
          curDotation.value = res.curDotation;
          blocked_users.value = res.blocked_users;
          blocked_by_users.value = res.blocked_by_users;
          trialDuration.value = res.trialDuration || 3;
          classifiedAdsParams.value = res.classifiedAds || { allowed: false };

          if (res.affiliate_coupon) {
            user.value.affiliate_coupon = res.affiliate_coupon;
          }

          if (Capacitor.isNativePlatform()) {
            // init subscription manager
            try {
              const { connectUser } = useGlassfy();
              await connectUser();
            } catch (e) {
              console.error(e);
              console.error("Error while connecting user to glassfy");
            }
            try {
              // set user id in analytics
              await FirebaseAnalytics.setUserId({
                userId: "" + user.value.id,
              });
            } catch (e) {
              console.error(e);
              console.error("Error while registering user in analytics");
            }
          }
        }
      } catch (e) {
        console.error(e);
        setToken(null);
      }
    }
    return user;
  };

  const checkUserTrial = async () => {
    if (token.value && user.value) {
      const res = await client("/users/app/trial", { params: {} });
      if (res && res.trial_end) {
        Object.assign(user.value, res);
      }
    }
  };

  const setSponsor = async (code) => {
    if (token.value && user.value && code != null && code.trim() != "") {
      const res = await client("/users/app/sponsor", {
        method: "POST",
        data: { code },
      });
      if (res && res.found) {
        await fetchUser(true);
      }
      return res;
    }
  };

  const hasFavorite = (category, entry) => {
    return Boolean(
      favorites.value && favorites.value.find((entId) => entId == entry?.id)
    );
  };

  const addToFavorites = async (category, entry) => {
    if (!entry?.id) return;
    try {
      await client("/users/favorites/add", {
        method: "POST",
        data: { entry_id: entry.id },
      });
      //console.log(user.value.username);
      favorites.value.push(entry.id);
    } catch (e) {
      // let it be...
      console.error(e);
    }
  };

  const removeFromFavorites = async (category, entry) => {
    if (!entry?.id) return;
    try {
      await client("/users/favorites/remove", {
        method: "POST",
        data: { entry_id: entry.id },
      });
      //console.log(user.value.username);
      if (favorites.value) {
        const idx = favorites.value.findIndex((enId) => enId == entry.id);
        if (idx > -1) {
          favorites.value.splice(idx, 1);
        }
      }
    } catch (e) {
      // let it be...
      console.error(e);
    }
  };

  const interact = async (userId, { block, mask, follow }) => {
    if (user.value && userId) {
      try {
        const res = await client("/user-interactions/interact", {
          method: "POST",
          data: { on: userId, block, mask, follow },
        });
        if (res.success) {
          if (block === true) {
            blocked_users.value.push(userId);
          } else if (block === false) {
            const idx = blocked_users.value.findIndex((uid) => uid === userId);
            if (idx > -1) {
              blocked_users.value.splice(idx, 1);
            }
          }
        }
        return res;
      } catch (e) {
        // let it be...
        console.error(e);
        return { success: false };
      }
    }
  };

  const isBlocked = (userId) => {
    return blocked_users.value.indexOf(userId) > -1;
  };
  const isBlockedBy = (userId) => {
    return blocked_by_users.value.indexOf(userId) > -1;
  };
  const blockUser = async (userId) => {
    return interact(userId, { block: true });
  };
  const unblockUser = async (userId) => {
    return interact(userId, { block: false });
  };
  const sponsorSetSon = async (userId, sponsorCodeIfAdmin) => {
    if (user.value && userId) {
      try {
        const res = await client("/users/app/sponsor_to_son", {
          method: "POST",
          data: {
            sonId: userId,
            code: sponsorCodeIfAdmin || user.value.affiliate_coupon,
          },
        });
        return res;
      } catch (e) {
        // let it be...
        console.error(e);
        return { error: true };
      }
    }
  };
  const login = async (data) => {
    setToken(null);

    const { jwt } = await client(
      data.provider ? "/users/connect_with/" + data.provider : "/auth/local",
      {
        method: "POST",
        data,
      }
    );

    setToken(jwt);

    const user = await fetchUser();

    if (user && user.value && jwt) {
      return {
        user,
        jwt,
      };
    } else {
      console.log("An error occured while fetching user");
      throw { response: { data: { error: { message: "error.fetchuser" } } } };
    }
  };

  const register = async (data) => {
    setToken(null);

    const { jwt } = await client("/auth/local/register", {
      method: "POST",
      data,
    });

    setToken(jwt);

    const user = await fetchUser();

    if (user && user.value && jwt) {
      return {
        user,
        jwt,
      };
    } else {
      console.log("An error occured while fetching user");
      throw { response: { data: { error: { message: "error.fetchuser" } } } };
    }
  };

  const logout = () => {
    try {
      if (Capacitor.isNativePlatform()) {
        GoogleAuth.signOut();
      }
    } catch (e) {
      // do nothing
    }
    setToken(null);
  };

  const forgotPassword = async (data) => {
    setToken(null);
    await client("/auth/forgot-password", { method: "POST", data });
  };

  const resetPassword = async (data) => {
    setToken(null);
    const { jwt } = await client("/auth/reset-password", {
      method: "POST",
      data,
    });

    //setToken(jwt);
    //const user = await fetchUser();
    if (jwt) {
      return {
        success: true, // we don't log user in because we are in the web version, not the app
      };
    } else {
      console.log("An error occured while fetching user");
      throw { response: { data: { error: { message: "error.fetchuser" } } } };
    }
  };

  const changePassword = async (data) => {
    await client("/auth/change-password", { method: "POST", data });
  };

  const sendEmailConfirmation = async (data) => {
    await client("/auth/send-email-confirmation", {
      method: "POST",
      data,
    });
  };

  const getProviderAuthenticationUrl = (provider) => {
    return `${baseURL}/connect/${provider}`;
  };

  const authenticateProvider = async (provider, access_token) => {
    setToken(null);

    const { jwt } = await client(`/auth/${provider}/callback`, {
      method: "GET",
      params: { access_token },
    });

    setToken(jwt);

    const user = await fetchUser();

    if (user && user.value && jwt) {
      return {
        user,
        jwt,
      };
    } else {
      console.log("An error occured while fetching user");
      throw { response: { data: { error: { message: "error.fetchuser" } } } };
    }
  };

  const find = (contentType, params) => {
    return client(`/${contentType}`, { method: "GET", params });
  };

  const findOne = (contentType, id, params) => {
    if (typeof id === "object") {
      params = id;
      id = undefined;
    }

    const path = [contentType, id].filter(Boolean).join("/");

    return client(path, { method: "GET", params });
  };

  const create = (contentType, data) => {
    return client(`/${contentType}`, { method: "POST", data });
  };

  const update = (contentType, id, data) => {
    if (typeof id === "object") {
      data = id;
      id = undefined;
    }

    const path = [contentType, id].filter(Boolean).join("/");

    return client(path, { method: "PUT", data });
  };

  const _delete = (contentType, id) => {
    const path = [contentType, id].filter(Boolean).join("/");

    return client(path, { method: "DELETE" });
  };

  const removeToken = () => {
    setToken(null);
  };

  const updatePreferences = async (field, value) => {
    if (!user.value && localStorage) {
      // portal case -> save it locally
      const prefsstr = localStorage["blue_preferences"],
        prefs = prefsstr ? JSON.parse(prefsstr) : {};
      prefs[field] = value;
      localStorage["blue_preferences"] = JSON.stringify(prefs);
      localPreferences.value = prefs;
      return;
    }
    if (!user.value.preferences) {
      user.value.preferences = {};
    }
    user.value.preferences[field] = value;
    await client(`/users/${user.value?.id}`, {
      method: "PUT",
      data: { data: { preferences: user.value.preferences } },
    });
  };
  /* used if preference is an object */
  const updatePreferencesKey = async (field, key, value) => {
    if (!user.value && localStorage) {
      // portal case -> save it locally
      const prefsstr = localStorage["blue_preferences"],
        prefs = prefsstr ? JSON.parse(prefsstr) : {};
      if (!prefs[field]) prefs[field] = {};
      prefs[field][key] = value;
      localStorage["blue_preferences"] = JSON.stringify(prefs);
      localPreferences.value = prefs;
      return;
    }
    if (!user.value.preferences) {
      user.value.preferences = {};
    }
    if (!user.value.preferences[field]) user.value.preferences[field] = {};
    user.value.preferences[field][key] = value;
    await client(`/users/${user.value?.id}`, {
      method: "PUT",
      data: { data: { preferences: user.value.preferences } },
    });
  };

  const getPreferences = (field) => {
    if (!user.value && localStorage) {
      // portal case -> save it locally
      if (localPreferences.value == null) {
        const prefsstr = localStorage["blue_preferences"],
          prefs = prefsstr ? JSON.parse(prefsstr) : {};
        localPreferences.value = prefs;
      }
      return localPreferences.value[field];
    }
    return (user.value?.preferences && user.value.preferences[field]) || {};
  };
  /* used if preference is an object */
  const getPreferencesKey = (field, key) => {
    if (!user.value && localStorage) {
      // portal case -> save it locally
      if (localPreferences.value == null) {
        const prefsstr = localStorage["blue_preferences"],
          prefs = prefsstr ? JSON.parse(prefsstr) : {};
        localPreferences.value = prefs;
      }
      const p =
        localPreferences.value[field] && localPreferences.value[field][key];
      return p == null ? null : p;
    }
    const pref =
      user.value?.preferences &&
      user.value.preferences[field] &&
      user.value.preferences[field][key];
    return pref == null ? null : pref;
  };

  const isBetaUser = () => {
    return user.value?.is_admin || user.value?.is_beta;
  };

  return {
    token,
    user,
    initToken,
    removeToken,
    fetchUser,
    login,
    logout,
    register,
    forgotPassword,
    resetPassword,
    changePassword,
    sendEmailConfirmation,
    getProviderAuthenticationUrl,
    authenticateProvider,
    find,
    findOne,
    create,
    update,
    delete: _delete,
    client,
    favorites,
    dailyInfos,
    reefers,
    curDotation,
    trialDuration,
    classifiedAdsParams,
    hasFavorite,
    addToFavorites,
    removeFromFavorites,
    setNotificationToken,
    isBlocked,
    isBlockedBy,
    blockUser,
    unblockUser,
    sponsorSetSon,
    checkUserTrial,
    updateRecentReefers,
    updatePreferences,
    updatePreferencesKey,
    getPreferences,
    getPreferencesKey,
    setSponsor,
    prefs: {
      MEASURES: "measures",
      UNITS: "units",
      VOLUME_CHANGE: "volume_change",
      TWEAK: "tweak",
      MODALS: "modals",
      ADS: "ads",
    },
    isBetaUser,
  };
};
