<template>
  <div class="flex items-center justify-center">
    <blue-title class="grow"><slot></slot></blue-title>
    <div class="shrink-0 flex items-center justify-end gap-2 text-xl">
      <img :src="props.image" v-if="props.image" class="w-5" />
      <ion-icon v-else :icon="props.icon || $icons.image"></ion-icon>
      <blue-toggle
        :modelValue="modelValue"
        @update:modelValue="update"
        noLabel
      />
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  modelValue: Boolean,
  noPadding: Boolean,
  icon: Object,
  image: String,
});
const emit = defineEmits(["update:modelValue"]);
const update = (val) => {
  emit("update:modelValue", val);
};
</script>
