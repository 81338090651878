<template>
  <div
    class="cursor-pointer underline"
    :class="{
      'text-sun': !props.dangerous && !props.grey,
      'text-red-400': props.dangerous,
      'text-bluegrey': props.grey,
      'text-baseplus': !props.small,
      'text-base': props.small,
    }"
    @click="clickLink"
  >
    <slot></slot>
  </div>
</template>
<script setup>
import { Browser } from "@capacitor/browser";
const props = defineProps({
  dangerous: Boolean,
  grey: Boolean,
  href: String,
  small: Boolean,
});
const emit = defineEmits(["click"]);
const clickLink = async (evt) => {
  if (props.href) {
    evt.preventDefault();
    evt.stopPropagation();

    await Browser.open({ url: props.href });
  } else {
    emit("click", evt);
  }
};
</script>
