<template>
  <div
    class="overflow-visible relative bubble-wrapper"
    @click="count($event)"
    id="bubble-fish"
  >
    <div
      :key="i"
      ref="bubbleEls"
      v-for="i in 6"
      :class="{
        ['bubble-' + i]: true,
        ['alt-' + ((curAlts[i] && curAlts[i].nb) || 0)]:
          curAlts[i] && curAlts[i].active,
      }"
    ></div>
    <img
      src="/assets/fish.png"
      :style="{ 'max-height': '36px' }"
      class="absolute top-0 right-0"
    />
  </div>
  <ion-popover
    class="smooth bubble"
    size="auto"
    mode="ios"
    :arrow="true"
    alignment="center"
    @didDismiss="isOpened = false"
    :backdropDismiss="false"
    :dismiss-on-select="true"
    :is-open="isOpened"
    :event="refEvent"
  >
    <div class="p-4">{{ curPhrase }}</div>
  </ion-popover>
</template>
<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { useStorage } from "@/composable/useStorage";
import { useI18n } from "vue-i18n";

const bubbleEls = ref([]);
const storage = useStorage();
const { t } = useI18n();

const curAlts = ref([]),
  curUpdate = ref(-1),
  curTimer = ref();

const isOpened = ref(false),
  refEvent = ref(),
  curPhrase = ref();
const count = (ev) => {
  storage.setBubble(storage.bubble + 1);

  curUpdate.value++;
  if (curUpdate.value >= bubbleEls.value.length) {
    curUpdate.value = 0;
  }
  curAlts.value[curUpdate.value + 1] = {
    active: true,
    nb: Math.floor(Math.random() * 8) + 1,
  };
  resetTimer();
  // display text !
  if (storage.bubble % 10 === 0) {
    isOpened.value = true;
    refEvent.value = ev;
    const curph = Math.floor(Math.random() * 102) + 1;
    curPhrase.value = t("fish." + curph);
  }
};

const curKilled = ref();
onMounted(() => {
  resetTimer();
});

const resetTimer = () => {
  clearTimeout(curTimer.value);
  curTimer.value = setTimeout(killBubble, 800);
  curKilled.value = curAlts.value.length;
};

const killBubble = () => {
  curKilled.value--;
  if (curKilled.value > 0) {
    curAlts.value[curKilled.value].active = false;
    curTimer.value = setTimeout(killBubble, 100 * curKilled.value);
  }
};

onUnmounted(() => {
  clearTimeout(curTimer.value);
});
</script>
<style>
.bubble-wrapper {
  width: 44px;
  height: 36px;
  position: relative;
}
.bubble-wrapper > div {
  background: transparent linear-gradient(180deg, #f19f90 0%, #ec5b8b 100%) 0%
    0% no-repeat padding-box;
  position: absolute;
  border-radius: 50px;
  transition: all 0.25s;
}
.bubble-wrapper > div.alt-1 {
  background: transparent linear-gradient(180deg, #ed7965 0%, #eb2c6c 100%) 0%
    0% no-repeat padding-box;
  transform: scale(1.5);
}
.bubble-wrapper > div.alt-2 {
  background: transparent linear-gradient(180deg, #edab65 0%, #ebcb2c 100%) 0%
    0% no-repeat padding-box;
  transform: scale(1.5);
}
.bubble-wrapper > div.alt-3 {
  background: transparent linear-gradient(180deg, #cded65 0%, #85eb2c 100%) 0%
    0% no-repeat padding-box;
  transform: scale(1.5);
}
.bubble-wrapper > div.alt-4 {
  background: transparent linear-gradient(180deg, #65edd4 0%, #2ce5eb 100%) 0%
    0% no-repeat padding-box;
  transform: scale(1.5);
}
.bubble-wrapper > div.alt-5 {
  background: transparent linear-gradient(180deg, #6589ed 0%, #2c5ceb 100%) 0%
    0% no-repeat padding-box;
  transform: scale(1.5);
}
.bubble-wrapper > div.alt-6 {
  background: transparent linear-gradient(180deg, #8b65ed 0%, #682ceb 100%) 0%
    0% no-repeat padding-box;
  transform: scale(1.5);
}
.bubble-wrapper > div.alt-7 {
  background: transparent linear-gradient(180deg, #cf65ed 0%, #c52ceb 100%) 0%
    0% no-repeat padding-box;
  transform: scale(1.5);
}
.bubble-wrapper > div.alt-8 {
  background: transparent linear-gradient(180deg, #ed6580 0%, #eb2c7c 100%) 0%
    0% no-repeat padding-box;
  transform: scale(1.5);
}

@keyframes bubbleOne {
  0% {
    bottom: 30px;
    left: 9px;
    opacity: 0;
  }
  50% {
    bottom: 45px;
    left: 16px;
    opacity: 1;
  }
  100% {
    bottom: 65px;
    left: 4px;
    opacity: 0;
  }
}

.bubble-wrapper .bubble-1 {
  width: 4px;
  height: 4px;
  animation: bubbleOne 4.1s linear infinite;
}

@keyframes bubbleTwo {
  0% {
    bottom: 25px;
    left: 2px;
    opacity: 0;
  }
  50% {
    bottom: 38px;
    left: -1px;
    opacity: 1;
  }
  100% {
    bottom: 50px;
    left: 1px;
    opacity: 0;
  }
}

.bubble-wrapper .bubble-2 {
  width: 4px;
  height: 4px;
  animation: bubbleTwo 2.4s linear infinite;
}

@keyframes bubbleThree {
  0% {
    bottom: 20px;
    left: 6px;
    opacity: 0;
  }
  50% {
    bottom: 30px;
    left: 12px;
    opacity: 1;
  }
  100% {
    bottom: 40px;
    left: 9px;
    opacity: 0;
  }
}

.bubble-wrapper .bubble-3 {
  width: 3px;
  height: 3px;
  animation: bubbleThree 3.6s linear infinite;
}

@keyframes bubbleFour {
  0% {
    bottom: 15px;
    left: -1px;
    opacity: 0;
  }
  50% {
    bottom: 36px;
    left: 2px;
    opacity: 1;
  }
  100% {
    bottom: 58px;
    left: -2px;
    opacity: 0;
  }
}

.bubble-wrapper .bubble-4 {
  width: 6px;
  height: 6px;
  animation: bubbleFour 1.8s linear infinite;
}

@keyframes bubbleFive {
  0% {
    bottom: 15px;
    left: 7px;
    opacity: 0;
  }
  50% {
    bottom: 36px;
    left: 14px;
    opacity: 1;
  }
  100% {
    bottom: 58px;
    left: 4px;
    opacity: 0;
  }
}

.bubble-wrapper .bubble-5 {
  width: 3px;
  height: 3px;
  animation: bubbleFive 3.6s linear infinite;
}

@keyframes bubbleSix {
  0% {
    bottom: 10px;
    left: 4px;
    opacity: 0;
  }
  50% {
    bottom: 30px;
    left: -6px;
    opacity: 1;
  }
  100% {
    bottom: 50px;
    left: 4px;
    opacity: 0;
  }
}

.bubble-wrapper .bubble-6 {
  width: 4px;
  height: 4px;
  animation: bubbleSix 2.4s linear infinite;
}
</style>
