<template>
  <div
    class="rounded bg-nomoonnight flex items-center justify-center p-4"
    @click="goToPage"
  >
    <div class="flex-grow flex gap-2 flex-wrap ml-2">
      <slot></slot>
    </div>
    <div class="shrink-0">
      <div class="cursor-pointer">
        <img
          src="/assets/micons/ic_fluent_arrow_circle_right_24_filled.svg"
          class="h-[22px]"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { useIonRouter } from "@ionic/vue";
const props = defineProps({
  to: String,
});
const ionRouter = useIonRouter();
const goToPage = () => {
  if (props.to) {
    ionRouter.push(props.to);
  }
};
</script>
