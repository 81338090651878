<template>
  <div
    class="flex items-center justify-center aspect-square rounded-full hover:opacity-80"
    :class="{
      'p-0 h-[18px]': props.xsmall,
      'p-0 h-[24px]': props.small,
      'p-1 h-[48px]': props.little,
      'p-1 h-[68px]': !props.small && !props.xsmall && !props.little,
      'bg-sun': !props.water && !props.internalTransparent,
      'bg-water': props.water && !props.internalTransparent,
      'border-[1px] border-water': props.water && props.internalTransparent,
    }"
  >
    <div
      class="flex items-center justify-center aspect-square rounded-full"
      :class="{
        'p-0 h-[18px] text-baseplus': props.xsmall,
        'p-0 h-[24px] text-lg': props.small,
        'p-4 h-[58px] text-2xl border-[1px] border-nightblue':
          !props.small && !props.xsmall && !props.little,
        'p-2 h-[38px] text-xl border-[1px] border-nightblue': props.little,
        'bg-sun': !props.water,
        'bg-water': props.water,
        'text-nightblue': !props.textWhite,
        'text-white': props.textWhite,
      }"
    >
      <img
        src="/assets/micons/ic_fluent_drop_24_filled.svg"
        :class="{ 'h-5': props.little, 'h-7': !props.little }"
        v-if="props.water"
      />
      <ion-icon v-else :icon="props.icon || $icons.addOutline"></ion-icon>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  icon: Object,
  little: Boolean,
  small: Boolean,
  xsmall: Boolean,
  water: Boolean,
  internalTransparent: Boolean,
  textWhite: Boolean,
});
</script>
