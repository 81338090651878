import { defineStore } from "pinia";
import { useStorage } from "@/composable/useStorage";

export const useEntries = defineStore("entries", {
  state: () => ({
    entries: { catById: {} }, // key : categoryId, value object indexed with { key : 'all' | linked category id , value : {list: list of entries, updated: Bool} }
  }),

  actions: {
    loadEntries() {
      const store = useStorage();
      this.entries = { catById: {} };
      store.coldata?.entries?.forEach((ent) => {
        if (!this.entries[ent.category])
          this.entries[ent.category] = { all: { list: [] } };
        this.entries[ent.category].all.list.push(ent);
        this.entries.catById[ent.id] = ent.category;
      });
      /*let shouldLoad = false,
        doSearch = false;
      if (this.entries[categoryId] == null) shouldLoad = true;
      else {
        const curList = this.entries[categoryId][linked_cat || "all"];
        if (curList == null) shouldLoad = true;
        else {
          if (!curList.updated) {
            return; // list is loaded / not updated
          } else {
            shouldLoad = true;
          }
        }
      }
      if (
        search_criteria &&
        search_criteria.f &&
        search_criteria.f.length > 0 &&
        search_criteria.q &&
        search_criteria.q != ""
      ) {
        shouldLoad = true; // searching entries with query, do not store results
        doSearch = true;
      }
      if (search_criteria && search_criteria.k) {
        shouldLoad = true; // searching entries with query, do not store results
      }
      //console.log("loadEntries", shouldLoad);
      if (shouldLoad) {
        try {
          //console.log('Load entries', categoryId)
          const { client } = useStrapi();
          const filters = { category: categoryId };
          if (linked_cat) {
            if (Array.isArray(linked_cat)) {
              filters.linked_entries = linked_cat;
            } else {
              filters.linked_entries = [linked_cat];
            }
          }
          const res = await client("/collection-entries", {
            params: {
              filters,
              search: doSearch ? search_criteria : null,
              populate: ["medias"],
              orderByRaw: { column: `name->>'${locale || "fr"}'` },
            },
          });
          const reslst = res.data.map((ent) => {
            const res = ent.attributes;
            res.id = ent.id;
            return res;
          });
          //console.log(reslst, categoryId, doSearch, search_criteria.k);
          if (this.entries[categoryId] == null) this.entries[categoryId] = {};
          if ((search_criteria && search_criteria.k) || doSearch) {
            this.entries[categoryId][search_criteria.k || "search"] = {
              list: reslst,
              updated: false,
            };
          } else {
            this.entries[categoryId][linked_cat || "all"] = {
              list: reslst,
              updated: false,
            };
          }
        } catch (e) {
          console.log(e);
        }
      }*/
    },
    find(entId, catId) {
      if (!entId) return null;
      if (!catId) {
        catId = this.entries.catById[entId];
      }
      if (!catId || !this.entries[catId]) return null;
      return this.entries[catId].all?.list?.find((e) => e.id == entId);
      /*if (catId) {
        if (this.entries[catId]) {
          let res = null;
          Object.keys(this.entries[catId]).forEach((k) => {
            const entFound = this.entries[catId][k].list?.find(
              (ent) => ent.id === entId
            );
            if (entFound) {
              res = entFound;
              return false;
            }
          });
          if (res) {
            return res;
          }
        }
      }
      const { findOne } = useStrapi();
      return findOne("/collection-entries", entId, {
        populate: ["medias", "category"],
      });*/
    },
  },
});
