<template>
  <div class="md" v-html="$mdit.render(props.source)" v-blue-links></div>
</template>
<script setup>
const props = defineProps({
  source: String,
});
</script>
<style>
.md p {
  padding: 0;
}
</style>
