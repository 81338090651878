<template>
  <div class="px-2 py-1 bg-deepblue rounded-[4px] inline-block">
    <ion-icon
      class="align-middle"
      style="width: 18px; height: 18px"
      :icon="props.icon || $icons.options"
    ></ion-icon>
    {{ props.label || $t("community.filter") }}
  </div>
</template>
<script setup>
const props = defineProps({
  label: String,
  icon: Object,
});
</script>
