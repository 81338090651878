<template>
  <div
    class="rounded-full bg-nomoonnight shadow-lg"
    :class="{ relative: props.ring }"
    :style="styleDiv"
  >
    <div
      class="rounded-full absolute border-[1px] top-[-4px] left-[-4px]"
      :style="{
        width: 'calc(100% + 8px)',
        height: 'calc(100% + 8px)',
        'border-color': statusColor,
      }"
      v-if="props.ring && statusColor"
    ></div>
    <!--<div
        class="absolute bottom-[2px] right-[2px] w-[11px] h-[11px] rounded-full"
        :style="{ 'background-color': statusColor }"
      ></div>-->
    <slot></slot>
  </div>
</template>
<script setup>
import { computed } from "vue";
const props = defineProps({
  avatar: Object,
  style: Object,
  user: Object,
  ring: Boolean,
});

const colors = {
  admin: "#5AD3FF",
  member: "#E8A066",
  pro: "#FFCF1A",
  moderator: "#45CC65",
};

const url = computed(() => {
  const chosen_avatar = props.user?.chosen_avatar;
  if (chosen_avatar) {
    return "/assets/avatars/" + chosen_avatar + ".png";
  } else {
    const res =
      props.avatar?.data?.attributes?.formats?.thumbnail?.url ||
      props.avatar?.formats?.thumbnail?.url ||
      props.avatar?.data?.attributes?.url ||
      props.avatar?.url;
    if (!res) {
      const avatarId = ((props.user?.id || 0) % 6) + 1;
      return "/assets/avatars/" + avatarId + ".png";
    } else {
      return res;
    }
  }
});

const styleDiv = computed(() => {
  return {
    "aspect-ratio": 1,
    ...(props.style || {}),
    "background-repeat": "no-repeat",
    "background-position": "center center",
    "background-size": "cover",
    "background-image": "url(" + (url.value || "/assets/aquarium.png") + ")",
  };
});

const statusColor = computed(() => {
  if (props.user?.is_admin) {
    return colors.admin;
  } else if (props.user?.is_pro) {
    return colors.pro;
  } else if (props.user?.has_sub) {
    return colors.member;
  }
  return null;
});
</script>
