<template>
  <span
    class="align-middle cursor-pointer"
    :id="'info-button-' + curid"
    @click.stop="() => {}"
  >
    <ion-icon
      :style="{
        height: props.iconSize + 'px',
        width: props.iconSize + 'px',
        'margin-top': '4px',
      }"
      :icon="props.icon || $icons.informationCircle"
      @click="opened = true"
    ></ion-icon>
    <div v-if="!modal">
      <ion-popover
        class="smooth info"
        :trigger="'info-button-' + curid"
        size="auto"
        mode="ios"
        :arrow="true"
        alignment="center"
        :dismiss-on-select="true"
      >
        <blue-alert :type="alertType" class="text-base">
          <blue-markdown :source="props.label" v-if="props.label" />
          <slot></slot>
        </blue-alert>
      </ion-popover>
    </div>
    <div v-else>
      <ion-modal
        :is-open="opened"
        @ionModalDidDismiss="opened = false"
        class="small-modal"
      >
        <div class="max-h-[85vh] overflow-auto">
          <div class="p-6 text-white">
            <blue-markdown :source="props.label" v-if="props.label" />
            <slot></slot>
          </div>
        </div>
      </ion-modal>
    </div>
    <span></span>
  </span>
</template>
<script setup>
import { ref } from "vue";

const props = defineProps({
  label: String,
  icon: Object,
  iconSize: {
    type: Number,
    default: 18,
  },
  alertType: {
    type: String,
    default: "info",
  },
  modal: Boolean,
});

const opened = ref(false);
const curid = ref(Math.random().toString(36).slice(2, 7));
</script>
