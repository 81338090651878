import { defineStore } from "pinia";
import { useStrapi } from "../composable/useStrapi";
import { useSettings } from "@/store/useSettings.js";
import { useEntries } from "@/store/useEntries.js";
import dayjs from "dayjs";

export const useMeasures = defineStore("measures", {
  state: () => ({
    lists: {},
    additions: {},
    icps: {},
    config: null,
    setting: null,
  }),

  getters: {},
  actions: {
    reset() {
      this.lists = {};
      this.additions = {};
      this.icps = {};
    },
    async loadConfig(force) {
      if (this.config == null || force) {
        const settings = useSettings();
        const entries = useEntries();

        if (settings.settings && settings.settings["measures"]) {
          this.setting = settings.settings["measures"];
          const catId = this.setting.category;
          if (catId) {
            if (catId) {
              const lst =
                (entries.entries[catId] && entries.entries[catId].all?.list) ||
                [];

              this.config = lst.map((entry) => {
                const res = { entryId: entry.id };
                Object.keys(this.setting).forEach((k) => {
                  if (
                    entry.values != null &&
                    entry.values[this.setting[k]] != null
                  ) {
                    res[k] = entry.values[this.setting[k]];
                  }
                });
                if (res.tags) {
                  res.tags = res.tags.map((entId) => {
                    const r = entries.find(entId);
                    return {
                      id: r.id,
                      name: r.name,
                      weight: r.values[this.setting.tag_weight],
                      order: r.values[this.setting.tag_order] || 1000,
                      color: r.values[this.setting.tag_color]
                        ? "#" + r.values[this.setting.tag_color]
                        : null,
                    };
                  });
                }
                return res;
              });
              /*console.log(
                this.config.map((el) => ({
                  id: el.id,
                  name_fr: el.name?.fr,
                  unit: el.unit,
                }))
              );*/
              //console.log(this.config);
              /*const order = this.setting?.measures_order;
              this.config.sort((a, b) => {
                return order.indexOf(a.id) - order.indexOf(b.id);
              });*/
              this.config.sort((a, b) => {
                const cata = a.essential ? 2 : a.important ? 1 : 0,
                  catb = b.essential ? 2 : b.important ? 1 : 0;
                if (cata === catb) {
                  return a.abbr.localeCompare(b.abbr);
                } else {
                  return catb - cata;
                }
              });
            }
          }
        }
      }
    },

    async getMeasure(aquariumId, measureId) {
      await this.loadMeasure(aquariumId, measureId);
      return (
        this.lists[aquariumId] &&
        this.lists[aquariumId].find((m) => m.id == measureId)
      );
    },
    async reloadMeasure(aquariumId, measureId) {
      await this.loadMeasure(aquariumId, measureId, true);
      return;
    },
    async loadMeasure(aquariumId, measureId, force = false) {
      if (!this.lists[aquariumId] || this.lists[aquariumId].updated || force) {
        try {
          const { findOne } = useStrapi();
          const ent = await findOne("/measures", measureId, {
            populate: ["aquarium"],
          });
          const res = ent.data?.attributes;
          res.id = ent.data?.id;

          if (res.aquarium?.data?.id != aquariumId) {
            console.log(
              "Measure",
              measureId,
              "is accessed for aquarium",
              aquariumId,
              "but it appears to come from",
              res.aquarium?.data?.id,
              "-> Fail to retrieve"
            );
            return;
          }

          delete res.aquarium;

          if (!this.lists[aquariumId]) {
            this.lists[aquariumId] = [];
          }

          const alreadyIdx = this.lists[aquariumId].findIndex(
            (e) => e.id == measureId
          );
          if (alreadyIdx === -1) {
            this.lists[aquariumId].push(res);
          } else {
            this.lists[aquariumId][alreadyIdx] = res;
          }

          this.lists[aquariumId].sort(
            (a, b) =>
              (b.date ? new Date(b.date).getTime() : 0) -
              (a.date ? new Date(a.date).getTime() : 0)
          );
        } catch (e) {
          console.log(e);
        }
      }
    },
    async loadMeasures(aquariumId /*, next = false*/) {
      // TODO load next
      // TODO check sort is ok
      if (!this.lists[aquariumId] || this.lists[aquariumId].updated) {
        try {
          const { client } = useStrapi();
          const res = await client("/measures/w-adds", {
            params: {
              filters: { aquarium: aquariumId },
              pagination: { pageSize: 100, withCount: true },
              sort: { date: "desc" },
            },
          });
          if (!this.lists[aquariumId]) {
            this.lists[aquariumId] = [];
          }
          const resmeasures = res && res.measures;
          if (resmeasures?.data) {
            resmeasures.data.forEach((ent) => {
              const ratts = ent.attributes;
              ratts.id = ent.id;

              const alreadyIdx = this.lists[aquariumId].findIndex(
                (e) => e.id == ent.id
              );
              if (alreadyIdx === -1) {
                this.lists[aquariumId].push(ratts);
              } else {
                this.lists[aquariumId][alreadyIdx] = ratts;
              }
            });
          }
          this.additions[aquariumId] = res.additions;
          this.matchAdditions(aquariumId, res.additions);

          this.icps[aquariumId] = res.icps;

          // sort again because there may be easure that was there before push
          this.lists[aquariumId].sort(
            (a, b) =>
              (b.date ? new Date(b.date).getTime() : 0) -
              (a.date ? new Date(a.date).getTime() : 0)
          );
          this.lists[aquariumId].updated = false;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async matchAdditions(aquariumId, additions) {
      if (additions && additions.length > 0) {
        additions.forEach((add) => {
          if (add.data.additions) {
            for (const part of add.data.additions) {
              if (this.lists[aquariumId]) {
                this.lists[aquariumId].forEach((m) => {
                  if (
                    dayjs(m.date)
                      .set("hour", 0)
                      .set("minute", 0)
                      .set("second", 0)
                      .isBefore(dayjs(part.date)) &&
                    dayjs(part.date).isBefore(dayjs(m.date).add(1, "day"))
                  ) {
                    if (!m.additions) m.additions = {};
                    if (!m.additions[add.element])
                      m.additions[add.element] = { list: [] };

                    const foundIdx = m.additions[add.element].list.findIndex(
                      (a) => a.id === add.id
                    );
                    if (foundIdx === -1) {
                      m.additions[add.element].list.push(add);
                    } else {
                      m.additions[add.element].list[foundIdx] = add;
                    }
                    m.additions[add.element].v = Math.max(
                      ...m.additions[add.element].list
                        .map((a) => a.data?.to?.v)
                        .filter(Boolean)
                    );
                  }
                });
              }
            }
          }
        });
      }
    },
    async removeAddition(aquariumId, add) {
      this.lists[aquariumId].forEach((m) => {
        if (
          m.additions &&
          m.additions[add.element] &&
          m.additions[add.element].list
        ) {
          const foundIdx = m.additions[add.element].list.findIndex(
            (a) => a.id === add.id
          );
          if (foundIdx > -1) {
            m.additions[add.element].list.splice(foundIdx, 1);
            m.additions[add.element].v = Math.max(
              ...m.additions[add.element].list
                .map((a) => a.data?.to?.v)
                .filter(Boolean)
            );
          }
        }
      });
    },
    async create(data) {
      try {
        const { create } = useStrapi();
        const res = await create("measures", {
          data,
        });
        if (this.lists[data.aquarium]) {
          this.lists[data.aquarium].updated = true;
        }
        return res;
      } catch (e) {
        console.log(e);
      }
    },
    async update(id, data) {
      try {
        const { update } = useStrapi();
        const res = await update("measures", id, {
          data,
        });
        if (this.lists[data.aquarium]) {
          this.lists[data.aquarium].updated = true;
        }
        return res;
      } catch (e) {
        console.log(e);
      }
    },
  },
});
