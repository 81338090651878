<template>
  <h1
    class="text-baseplus uppercase font-bold"
    :class="{ 'pb-2': !props.noPadding }"
  >
    <slot></slot>
  </h1>
</template>
<script setup>
const props = defineProps({
  noPadding: Boolean,
});
</script>
