import { useStrapi } from "@/composable/useStrapi";

const roundDefault = (value, decimals) => {
  return Number(Number(value).toFixed(decimals || 0));
};

const roundExcess = (value, decimals) => {
  const pow10 = Math.pow(10, decimals || 0);
  const floored = Math.floor(value * pow10);
  const res = Number(Number(floored / pow10).toFixed(decimals || 0));
  //console.log(value, decimals, pow10, floored, res);
  return res;
};

const salinityToDensity = (sal, temp) => {
  const dens =
    -0.157406 +
    temp *
      (0.06793952 -
        temp *
          (0.00909529 -
            temp *
              (0.0001001685 - temp * (1120083e-12 - 6.536332e-9 * temp)))) +
    sal *
      (0.824493 -
        temp *
          (0.0040899 -
            temp * (76438e-9 - temp * (8.2467e-7 - 5.3875e-9 * temp))) -
        Math.sqrt(sal) * (0.00572466 - temp * (10227e-8 - 16546e-10 * temp)) +
        48314e-8 * sal);

  return Number(Number(dens + 1e3).toFixed(1));
};

const densityToSalinity = (dens, temp) => {
  const d = dens - 999.96,
    limit = 1e4;
  let sals,
    salmin = 0,
    salmax = 100,
    erreur = 1,
    nouvdens,
    i = 0;
  while (Math.abs(erreur) > 1e-5 && i < limit) {
    sals = (salmin + salmax) / 2;
    nouvdens =
      -0.157406 +
      temp *
        (0.06793952 -
          temp *
            (0.00909529 -
              temp *
                (0.0001001685 - temp * (1120083e-12 - 6.536332e-9 * temp)))) +
      sals *
        (0.824493 -
          temp *
            (0.0040899 -
              temp * (76438e-9 - temp * (8.2467e-7 - 5.3875e-9 * temp))) -
          Math.sqrt(sals) *
            (0.00572466 - temp * (10227e-8 - 16546e-10 * temp)) +
          48314e-8 * sals);
    erreur = (nouvdens - d) / d;
    if (erreur > 0) {
      salmax = sals;
    } else {
      salmin = sals;
    }
    ++i;
  }
  if (i >= limit) {
    return null;
  }
  return Number(Number(sals).toFixed(2));
};

const tools = [
  {
    id: "sal",
    element: "sal",
    convert: (val, temp) => val,
    revert: (val, temp) => val,
  },
  {
    id: "dens",
    element: "dens",
    convert: (val, temp) => densityToSalinity(val, temp),
    revert: (val, temp) => salinityToDensity(val, temp),
  },
];

const all_units = [
  {
    key: "volume",
    default: {
      key: "liters",
      abbr: "L",
      decimals: 0,
    },
    others: [
      {
        key: "galons",
        abbr: "gal",
        conv: (val) => val / 3.785,
        reverse: (val) => 3.785 * val,
        decimals: 0,
      },
    ],
  },
  {
    key: "temp",
    elements: ["temp"],
    default: {
      key: "celsius",
      abbr: "°C",
      decimals: 1,
    },
    others: [
      {
        key: "farenheit",
        abbr: "°F",
        conv: (val) => (val * 9) / 5 + 32,
        reverse: (val) => ((val - 32) * 5) / 9,
        decimals: 1,
      },
    ],
  },
  {
    key: "kh",
    elements: ["kh"],
    default: {
      key: "dkh",
      abbr: "dkh",
      decimals: 1,
    },
    others: [
      {
        key: "meq/L",
        abbr: "meq/L",
        conv: (val) => val / 2.8,
        reverse: (val) => val * 2.8,
        decimals: 2,
      },
      {
        key: "ppm",
        abbr: "ppm",
        conv: (val) => val * 17.86,
        reverse: (val) => val / 17.86,
        decimals: 1,
      },
    ],
  },
  {
    key: "dens",
    elements: ["dens"],
    default: {
      key: "gcm3",
      abbr: "g/cm³",
      decimals: 1,
    },
    others: [
      {
        key: "kgm3",
        abbr: "kg/m³",
        conv: (val) => val / 1000,
        reverse: (val) => 1000 * val,
        decimals: 4,
      },
      {
        key: "ozfloz",
        abbr: "oz/fl oz",
        conv: (val) => val * 1.04317556,
        reverse: (val) => val / 1.04317556,
        decimals: 1,
      },
    ],
  },
  {
    key: "ml",
    default: {
      key: "ml",
      abbr: "ml",
      decimals: null,
    },
    others: [
      {
        key: "floz",
        abbr: "fl oz",
        conv: (val) => val * 0.033814,
        reverse: (val) => val / 0.033814,
        more_decimals: 2,
      },
    ],
  },
  {
    key: "g",
    default: {
      key: "g",
      abbr: "g",
      decimals: 0,
    },
    others: [
      {
        key: "oz",
        abbr: "oz",
        conv: (val) => val * 0.035274,
        reverse: (val) => val / 0.035274,
        decimals: 2,
      },
    ],
  },
];

export const useUnits = () => {
  const { getPreferences, prefs } = useStrapi();

  const getUnitOrDefaultDef = (key, unit_key) => {
    const def = all_units.find((u) => u.key === key);
    if (!unit_key) {
      return def.default;
    } else {
      if (def && def.default.key === unit_key) {
        return def.default;
      } else if (def) {
        const u = def.others.find((u) => u.key === unit_key);
        return u;
      }
    }
    return null;
  };

  /** Use preferences to display a value in unit */
  const getUnitValue = (key, val, decimals = 0) => {
    if (val == null) return val;
    const units = getPreferences(prefs.UNITS);
    const unit_key = units && units[key];
    const def = getUnitOrDefaultDef(key, unit_key);
    if (def && typeof def.conv === "function") {
      let decs = def.decimals == null ? decimals : def.decimals;
      if (def.more_decimals) decs += def.more_decimals;
      return Number(Number(def.conv(val)).toFixed(decs));
    } else {
      return val;
    }
  };

  const getUnitValueForMeasure = (elementId, val) => {
    if (val == null) return val;
    const unit = all_units.find(
      (u) => u.elements && u.elements.includes(elementId)
    );
    if (unit) {
      const units = getPreferences(prefs.UNITS);
      const unit_key = units && units[unit.key];
      const def = getUnitOrDefaultDef(unit.key, unit_key);
      if (def && typeof def.conv === "function") {
        return Number(Number(def.conv(val)).toFixed(def.decimals));
      }
    }
    return val;
  };

  const formatMeasure = (measure, val) => {
    return (
      getUnitValueForMeasure(measure.id, val) +
      " " +
      (getUnitAbbrForMeasure(measure.id) || measure.unit)
    );
  };

  /** Reverse convert value if not default */
  const saveValue = (key, val, decimals = 0) => {
    if (val == null) return val;
    const units = getPreferences(prefs.UNITS);
    const unit_key = units && units[key];
    const def = getUnitOrDefaultDef(key, unit_key);
    if (def && typeof def.reverse === "function") {
      let decs = def.decimals == null ? decimals : def.decimals;
      if (def.more_decimals) decs += def.more_decimals;
      return Number(Number(def.reverse(val)).toFixed(decs + 1));
    } else {
      return val;
    }
  };

  const saveValueForMeasure = (elementId, val) => {
    if (val == null) return val;
    const unit = all_units.find(
      (u) => u.elements && u.elements.includes(elementId)
    );
    if (unit) {
      const units = getPreferences(prefs.UNITS);
      const unit_key = units && units[unit.key];
      const def = getUnitOrDefaultDef(unit.key, unit_key);
      if (def && typeof def.reverse === "function") {
        return Number(Number(def.reverse(val)).toFixed(def.decimals + 1));
      } else {
        return val;
      }
    }
    return val;
  };

  const formatUnit = (key, val, decimals = 0) => {
    if (val == null) return val;
    const units = getPreferences(prefs.UNITS);
    const unit_key = units && units[key];
    const def = getUnitOrDefaultDef(key, unit_key);
    if (def) {
      if (typeof def.conv === "function") {
        let decs = def.decimals == null ? decimals : def.decimals;
        if (def.more_decimals) decs += def.more_decimals;
        return Number(def.conv(val)).toFixed(decs) + " " + def.abbr;
      } else {
        let decs = def.decimals == null ? decimals : def.decimals;
        if (def.more_decimals) decs += def.more_decimals;
        return Number(val).toFixed(decs) + " " + def.abbr;
      }
    } else {
      return Number(val).toFixed(decimals) + " " + def.abbr;
    }
  };

  const getUnitAbbrForMeasure = (elementId) => {
    const unit = all_units.find(
      (u) => u.elements && u.elements.includes(elementId)
    );
    if (unit) {
      const units = getPreferences(prefs.UNITS);
      const unit_key = units && units[unit.key];
      const def = getUnitOrDefaultDef(unit.key, unit_key);
      return def && def.abbr;
    }
    return null;
  };

  const getUnitAbbr = (key, val) => {
    const units = getPreferences(prefs.UNITS);
    const unit_key = units && units[key];
    const def = getUnitOrDefaultDef(key, unit_key);
    return def && def.abbr;
  };

  const isUnitDefaultForMeasure = (elementId) => {
    const unit = all_units.find(
      (u) => u.elements && u.elements.includes(elementId)
    );
    if (unit) {
      const units = getPreferences(prefs.UNITS);
      const unit_key = units && units[unit.key];
      return unit_key == null || unit_key == unit.default.key;
    }
    return true;
  };

  return {
    all_units,
    getUnitValue,
    getUnitValueForMeasure,
    getUnitAbbrForMeasure,
    formatMeasure,
    getUnitAbbr,
    saveValue,
    saveValueForMeasure,
    isUnitDefaultForMeasure,
    formatUnit,
    salinityToDensity,
    densityToSalinity,
    roundDefault,
    roundExcess,
    tools,
  };
};
