<template>
  <div class="px-2 py-1 bg-bluegrey rounded-[4px] inline-block">
    <slot></slot>
    <ion-icon
      class="align-middle"
      style="width: 18px; height: 18px"
      :icon="$icons.closeOutline"
      @click="remove"
    ></ion-icon>
  </div>
</template>
<script setup>
const emit = defineEmits(["remove"]);
const remove = () => {
  emit("remove");
};
</script>
