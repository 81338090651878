import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { Capacitor } from "@capacitor/core";
import { useStrapi } from "./useStrapi";
import { useGlassfy } from "./useGlassfy";
import { useNotifications } from "@/store/useNotifications";
import { useDms } from "@/store/useDms";
import { LocalNotifications } from "@capacitor/local-notifications";

export const usePushNotifications = () => {
  let currentId = 1;
  // called at app init to handle all calls
  const addPushNotificationsListeners = async () => {
    if (Capacitor.getPlatform() !== "web") {
      FirebaseMessaging.addListener("notificationReceived", async (event) => {
        console.log("notificationReceived: ", { event });
        // refresh notifications count so the counter on home page is refreshed
        const notifications = useNotifications();
        notifications.count();

        const shouldNotify = true;
        // check if notification is about a dm
        if (
          event.notification.data &&
          event.notification.data.conversation_id
        ) {
          // we should refresh dm count so the counter on home page is refreshed
          const dms = useDms();
          dms.count();

          // reload conversation messages
          dms.refreshLatestMessages(
            Number(event.notification.data.conversation_id)
          );
        }
        // if on android, notifications are not displayed in status bar when app is foreground, create a local notification to notify user
        if (Capacitor.getPlatform() === "android" && shouldNotify) {
          const result = await LocalNotifications.schedule({
            notifications: [
              {
                body: event.notification.body,
                title: event.notification.title,
                id: currentId++,
                extra: event.notification.data,
              },
            ],
          });
          console.log(result);
        }
        // TODO update badge on app natively ??
        /**
       * {
    "event": {
        "notification": {
            "id": "0:1700811797861930%2f0cb84e2f0cb84e",
            "data": {},
            "title": "Test",
            "body": "Test",
            "tag": "campaign_collapse_key_5336146131217568930"
        }
    }
}
       */
      });
      /**
       * localNotificationActionPerformed : 
       * {
    "actionId": "tap",
    "notification": {
        "body": "Hi romain, it's time to change water in your tank, you need to add 30 liters",
        "title": "Water change",
        "id": 1,
        "extra": {}
    }
}
       */
      LocalNotifications.addListener(
        "localNotificationActionPerformed",
        (event) => {
          console.log("localNotificationActionPerformed", event);
          const notifications = useNotifications();
          const nid = event?.notification?.extra?.notification_id;
          if (nid) {
            // set nid in shouldGo, its value is watched in App.vue to go to the right page
            notifications.shouldGo = nid;
          }
        }
      );
      FirebaseMessaging.addListener(
        "notificationActionPerformed",
        async (event) => {
          console.log("notificationActionPerformed: ", { event });
          const notifications = useNotifications();
          const nid = event?.notification?.data?.notification_id;
          if (nid) {
            // set nid in shouldGo, its value is watched in App.vue to go to the right page
            notifications.shouldGo = nid;
          }
          /**
       * {
    "event": {
        "actionId": "tap",
        "notification": {
            "id": "0:1700941260316353%2f0cb84e2f0cb84e",
            "data": {
                "google.delivered_priority": "normal",
                "google.sent_time": 1700941260306,
                "notification_id": "223",
                "google.ttl": 2419200,
                "google.original_priority": "normal",
                "from": "825273476980",
                "com.google.firebase.iid.WakeLockHolder.wakefulintent": true,
                "gcm.n.analytics_data": "Bundle[mParcelledData.dataSize=100]",
                "collapse_key": "app.bluereef.app"
            }
        }
    }
}
       */
        }
      );
    }
  };

  const initToken = async () => {
    if (Capacitor.getPlatform() === "web") {
      const { setNotificationToken } = useStrapi();
      await setNotificationToken("__test__web_token");
    } else {
      const { token } = await FirebaseMessaging.getToken();
      console.info("Registration token: ", token);
      const { setNotificationToken } = useStrapi();
      await setNotificationToken(token);

      const { setDevice } = useGlassfy();
      await setDevice(token);
    }
  };

  const registerNotifications = async () => {
    if (Capacitor.getPlatform() !== "web") {
      try {
        let permStatus = await FirebaseMessaging.checkPermissions();

        if (permStatus.receive === "prompt") {
          permStatus = await FirebaseMessaging.requestPermissions();
        }

        if (permStatus.receive !== "granted") {
          throw new Error("User denied permissions!");
        }
      } catch (e) {
        console.error(e);
        console.error(
          "registerNotifications : Push not implemented on web or refused"
        );
      }
    }
  };

  return {
    addPushNotificationsListeners,
    registerNotifications,
    initToken,
  };
};
