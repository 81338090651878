<template>
  <div class="alert relative" :class="props.type">
    <div class="alert-icon absolute top-0 flex items-center justify-center">
      <ion-icon :icon="icons[props.type]" />
    </div>
    <slot></slot>
  </div>
</template>
<script setup>
import { alertOutline } from "ionicons/icons";

const props = defineProps({
  type: {
    type: String,
    default: "info", // error, warning
  },
});
const icons = {
  info: alertOutline,
  warning: alertOutline,
  error: alertOutline,
};
</script>
<style scoped>
/** alertes */
.alert {
  border-width: 0px;
  border-top-width: 24px;
  border-radius: 10px;
  padding: 1rem 0.25rem;
  color: #3b2701;
  padding-top: 28px;
  text-align: center;
}
.alert-icon {
  left: 50%;
  transform: translateX(-50%);
  top: -24px;
  height: 48px;
  width: 48px;
  border-width: 12px;
  border-radius: 50px;
  z-index: 10;
  background-color: #fff !important;
}
.alert.warning {
  background-color: #ecb999;
  border-color: #ff9100;
  color: #121b45;
}
.alert.warning .alert-icon {
  color: #ff9100;
  border-color: #ff9100;
}
.alert.error {
  background-color: #f19f90;
  border-color: #ee563b;
  color: #871e0b;
}
.alert.error .alert-icon {
  color: #ee563b;
  border-color: #ee563b;
}
.alert.info {
  background-color: #99bbec;
  border-color: #4e80e4;
  color: #121b45;
}
.alert.info .alert-icon {
  color: #4e80e4;
  border-color: #4e80e4;
}
</style>
