<template>
  <div>
    <div
      class="ios13-segmented-control w-full rounded overflow-hidden"
      ref="baseSelector"
    >
      <span class="selection" ref="selection"></span>
      <div
        class="option"
        v-for="o of optionsExt"
        :key="o && o[props.optionValue]"
        @click="input(o)"
      >
        <input
          type="radio"
          :id="curid + '-segbut-' + (o && o[props.optionValue])"
          :value="o && o[props.optionValue]"
          :class="{ checked: o && o[props.optionValue] == selectedEntry }"
          ref="inputSeg"
        />
        <label
          :for="curid + '-segbut-' + (o && o[props.optionValue])"
          :class="{ selected: o && o[props.optionValue] == selectedEntry }"
        >
          <span>
            <ion-icon
              v-if="o.icon"
              :icon="$icons[o.icon]"
              class="text-lg inline-block align-middle"
              :style="{ color: o.color }"
            ></ion-icon>
            {{ o && o[props.optionLabel] }}
            <div
              v-if="o.unread"
              class="bg-rosalina w-[10px] h-[10px] rounded inline-block mb-1"
            ></div>
          </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { useTdb } from "@/composable/useTdb";
import { useI18n } from "vue-i18n";

const { tdb } = useTdb();
const { t } = useI18n();

const props = defineProps({
  modelValue: Number,
  options: Array,
  optionLabel: {
    type: String,
    default: "label",
  },
  optionValue: {
    type: String,
    default: "value",
  },
  reset: Boolean,
  i18n: Boolean,
});

const curid = ref(Math.random().toString(36).slice(2, 7));

const optionsExt = computed(() => {
  const res = [];
  if (props.reset) {
    res.push({ [props.optionLabel || "label"]: t("global.all"), code: null });
  }
  if (!props.i18n) return [...res, ...props.options];
  else {
    return [
      ...res,
      ...(props.options?.map((ent) => {
        if (ent == null) return null;
        return {
          ...ent,
          [props.optionLabel]: tdb(ent[props.optionLabel]),
        };
      }) || []),
    ];
  }
});

const selectedEntry = computed(() => {
  const v = optionsExt.value?.find((opt) =>
    props.optionValue
      ? opt[props.optionValue] == props.modelValue
      : opt.code == props.modelValue
  );
  return v && props.optionValue ? v[props.optionValue] : v;
});

const baseSelector = ref();
const inputSeg = ref([]);
const selection = ref();

onMounted(() => {
  window.addEventListener("resize", updatePillPosition); // Prevent pill from detaching from element when window resized. Becuase this is rare I haven't bothered with throttling the event
});

onUnmounted(() => {
  window.removeEventListener("resize", updatePillPosition);
});

const updatePillPosition = () => {
  const elidx = inputSeg.value.findIndex((e) => e.value == selectedEntry.value);
  if (elidx > -1) {
    moveBackgroundPillToElement(inputSeg.value[elidx], elidx);
  }
};

const moveBackgroundPillToElement = (elem, index) => {
  selection.value.style.transform =
    "translateX(" + elem.offsetWidth * index + "px)";
};

const emit = defineEmits(["update:modelValue"]);
watch(
  () => [selectedEntry.value, optionsExt.value],
  ([n, opts]) => {
    if (!n && opts && opts.length > 0) {
      emit("update:modelValue", opts[0][props.optionValue]);
    }
  },
  { immediate: true }
);

const input = (el) => {
  emit("update:modelValue", el && el[props.optionValue]);
};
watch(
  () => props.modelValue,
  () => {
    setTimeout(() => updatePillPosition(), 100);
  },
  { immediate: true }
);
</script>
<style scoped>
.ios13-segmented-control {
  --background: #0f1539;
  background: var(--background);
  border-radius: 9px;
  margin: 0;
  padding: 2px;
  border: none;
  outline: none;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ios13-segmented-control .option {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/*.ios13-segmented-control .option:hover input:not(:checked) + label span,
.ios13-segmented-control .option:active input:not(:checked) + label span,
.ios13-segmented-control .option:focus input:not(:checked) + label span {
  opacity: 0.2;
}*/

.ios13-segmented-control .option:active input:not(:checked) + label span {
  transform: scale(0.95);
}

.ios13-segmented-control .option label {
  position: relative;
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /*padding: 3px 3vmin;*/
  background: rgba(255, 255, 255, 0);
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  /*text-transform: uppercase;*/
  cursor: pointer;
  line-height: 28px;
}
.ios13-segmented-control .option label.selected {
  color: #0e153b;
  font-weight: 700;
}
.ios13-segmented-control .option label::before,
.ios13-segmented-control .option label::after {
  content: "";
  width: 1px;
  background: rgba(142, 142, 147, 0.15);
  position: absolute;
  top: 14%;
  bottom: 14%;
  border-radius: 10px;
  will-change: background;
  -webkit-transition: background 0.2s ease;
  transition: background 0.2s ease;
}

.ios13-segmented-control .option label::before {
  left: 0;
  transform: translateX(-0.5px);
}

.ios13-segmented-control .option label::after {
  right: 0;
  transform: translateX(0.5px);
}

.ios13-segmented-control .option:first-of-type {
  grid-column: 1;
  grid-row: 1;
  box-shadow: none;
}

.ios13-segmented-control .option:first-of-type label::before {
  opacity: 0;
}

.ios13-segmented-control .option:last-of-type label::after {
  opacity: 0;
}

.ios13-segmented-control .option input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  opacity: 0;
}

.ios13-segmented-control .selection {
  /*background: transparent linear-gradient(180deg, #f19f90 0%, #ec5b8b 100%) 0%
    0% no-repeat padding-box;*/
  background: #ffef4b 0% 0% no-repeat padding-box;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 1px 0 rgba(0, 0, 0, 0.04);
  border-radius: 7px;
  grid-column: 1;
  grid-row: 1;
  z-index: 2;
  will-change: transform;
  -webkit-transition: transform 0.2s ease;
  transition: transform 0.2s ease;
}

.ios13-segmented-control .option label span {
  display: block;
  position: relative;
  z-index: 2;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  will-change: transform;
  padding: 5px;
}

.ios13-segmented-control .option input:checked + label::before,
.ios13-segmented-control .option input:checked + label::after {
  background: var(--background);
  z-index: 1;
}

.ios13-segmented-control .option input.checked + label {
  cursor: default;
}
</style>
