import { defineStore } from "pinia";
import { useStrapi } from "../composable/useStrapi";
import { useStorage } from "@/composable/useStorage";

export const useAquariums = defineStore("aquariums", {
  state: () => ({
    list: [],
    updated: true,
    current: null,
  }),

  getters: {
    currentId: (state) => state.current,
    aquariumsSelect: (state) => {
      return state.list.map((ent) => {
        return {
          ...ent,
          code: ent.id,
          label: ent.name,
        };
      });
    },
  },
  actions: {
    reset() {
      this.list = [];
      this.updated = true;
      this.current = null;
    },

    async loadAquariums(force = false) {
      if (this.updated || force) {
        try {
          const { client } = useStrapi();
          const res = await client("/aquariums", {
            params: {
              populate: ["medias"],
            },
          });
          this.list = res.data.map((ent) => {
            const res = ent.attributes;
            res.id = ent.id;
            return res;
          });
          this.updated = false;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async create(data) {
      try {
        const { create } = useStrapi();
        const res = await create("aquariums", {
          data,
        });
        this.updated = true;
        return res;
      } catch (e) {
        console.log(e);
      }
    },
    async update(id, data) {
      try {
        const { update } = useStrapi();
        const res = await update("aquariums", id, {
          data,
        });
        const idx = this.list.findIndex((aq) => aq.id == id);
        if (idx > -1) {
          this.list[idx] = {
            ...this.list[idx],
            ...(res.data?.attributes || {}),
          };
        }
        this.updated = true;
        return res;
      } catch (e) {
        console.log(e);
      }
    },
    async addUpdateEntries(aquarium, entry, type, list) {
      if (!aquarium?.id || !entry?.id || !type) return { error: "missing" };
      try {
        const { update, findOne } = useStrapi();
        const aqua = await findOne("/aquariums", aquarium.id);
        if (!aqua || !aqua.data || !aqua.data.id) return { error: "notfound" };
        const content = aqua.data.attributes.config?.content || {};
        if (!content[type]) content[type] = {};
        content[type][entry.id] = list;
        const res = await update("aquariums", aquarium.id, {
          data: { config: { ...aqua.data.attributes.config, content } },
        });
        this.updated = true;
        return res;
      } catch (e) {
        console.log(e);
      }
    },
    async addUpdateMaterial(aquarium, type, material, idx) {
      if (!aquarium?.id || !type) return { error: "missing" };
      try {
        const { update, findOne } = useStrapi();
        const aqua = await findOne("/aquariums", aquarium.id);
        if (!aqua || !aqua.data || !aqua.data.id) return { error: "notfound" };
        const content = aqua.data.attributes.config?.content || {};
        if (!content.material) content.material = {};
        if (!content.material[type]) content.material[type] = [];
        if (idx != -1) {
          content.material[type][idx] = material;
        } else {
          content.material[type].push(material);
        }
        const res = await update("aquariums", aquarium.id, {
          data: { config: { ...aqua.data.attributes.config, content } },
        });
        this.updated = true;
        return res;
      } catch (e) {
        console.log(e);
      }
    },
    async removeMaterial(aquarium, type, idx) {
      if (!aquarium?.id || !type || idx == -1) return { error: "missing" };
      try {
        const { update, findOne } = useStrapi();
        const aqua = await findOne("/aquariums", aquarium.id);
        if (!aqua || !aqua.data || !aqua.data.id) return { error: "notfound" };
        const content = aqua.data.attributes.config?.content || {};
        if (
          !content.material ||
          !content.material[type] ||
          content.material[type].length == 0 ||
          content.material[type].length <= idx
        ) {
          return { error: "notfound" };
        }
        content.material[type].splice(idx, 1);

        const res = await update("aquariums", aquarium.id, {
          data: { config: { ...aqua.data.attributes.config, content } },
        });
        this.updated = true;
        return res;
      } catch (e) {
        console.log(e);
      }
    },
    markChange() {
      this.updated = true;
    },
    setCurrent(n) {
      const store = useStorage();
      store.setCurrentAquarium(n);
      this.current = n;
    },
  },
});
