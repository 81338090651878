import { ref } from "vue";
import { Glassfy } from "capacitor-plugin-glassfy";
import { isPlatform } from "@ionic/vue";
import dayjs from "dayjs";
import { useStrapi } from "./useStrapi";
import { useSubscribe } from "./useSubscribe";
import { Capacitor } from "@capacitor/core";

const GLASSFY_API_KEY =
  process.env.GLASSFY_API_KEY || "b472d9d184e54e06a9c32f6584df2c02";

const PERMISSIONS_DEFAULT = [
  {
    permissionId: "blue-pro",
    entitlement: -9,
    isValid: false,
    expireDate: 0,
    accountableSkus: [],
  },
];

const OFFERINGS_DEFAULT = [
  {
    offeringId: "blue_subscription_offer",
    skus: [
      {
        skuId: "blue_subscription_month_7_99",
        productId: "android_blue_subscription_month_7.99",
        store: 2,
        extravars: {
          pro: "true",
        },
        product: {
          identifier: "android_blue_subscription_month_7.99",
          description: "Gérez votre aquarium avec Blue",
          title: "Abonnement mensuel (app.bluereef.app (unreviewed))",
          price: 4.99,
          currencyCode: "EUR",
          introductoryPrice: {
            price: 0,
            period: "P3D",
            numberOfPeriods: 1,
            type: "introductory",
            currencyCode: "EUR",
            identifier: "android_blue_subscription_month_7.99",
          },
        },
      },
      {
        skuId: "blue_subscription_year_80",
        productId: "android_blue_subscription_year_80",
        store: 2,
        extravars: {
          annual: "true",
          pro: "true",
          discount: "10",
        },
        product: {
          identifier: "android_blue_subscription_year_80",
          description: "",
          title: "Abonnement annuel (app.bluereef.app (unreviewed))",
          price: 54.99,
          currencyCode: "EUR",
          introductoryPrice: {
            price: 0,
            period: "P3D",
            numberOfPeriods: 1,
            type: "introductory",
            currencyCode: "EUR",
            identifier: "android_blue_subscription_year_80",
          },
        },
      },
    ],
  },
];

const permissions = ref();
const offerings = ref();
const no_glassfy = ref(false);
const initialized = ref(false);
export const useGlassfy = () => {
  const initGlassfy = async () => {
    if (initialized.value) return;
    if (Capacitor.isNativePlatform()) {
      try {
        // Initialise Glassfy
        await Glassfy.initialize({
          apiKey: GLASSFY_API_KEY,
          watcherMode: false,
        });
        // Get all user permissions
        permissions.value = await Glassfy.permissions();
        // Get all offerings (products)
        offerings.value = await Glassfy.offerings();
        initialized.value = true;
      } catch (e) {
        console.log("init error: ", e);
        // dev
        no_glassfy.value = true;
        initialized.value = true;
      }
    } else {
      no_glassfy.value = true;
      initialized.value = true;

      // Uncomment to test permissions
      /*permissions.value = { all: PERMISSIONS_DEFAULT };
      offerings.value = { all: OFFERINGS_DEFAULT };*/
    }
  };

  const purchase = async (sku /*: GlassfySku*/) => {
    // keep only this to test purchase behavior locally
    /*const { setShouldSubscribe } = useSubscribe();
    setShouldSubscribe(false);
    return { purchased: true };*/
    try {
      const transaction = await Glassfy.purchaseSku({ sku });
      if (transaction) {
        const perm = transaction.permissions?.all?.find(
          (p) => p.permissionId === "blue-pro"
        );
        const isValid = Boolean(perm && perm.isValid);
        // check permissions
        if (isValid) {
          // save user has bought a subscription
          const { client, user } = useStrapi();
          const toUpdate = {};
          if (isPlatform("ios")) {
            toUpdate.sub_ios = true;
          }
          if (isPlatform("android")) {
            toUpdate.sub_android = true;
          }
          await client(`/users/${user.value?.id}`, {
            method: "PUT",
            data: { data: toUpdate },
          });
          // Reload user permissions
          permissions.value = transaction.permissions;
          const { setShouldSubscribe } = useSubscribe();
          setShouldSubscribe(false);
          return { purchased: true };
        }
      }
      return { purchased: false };
    } catch (e) {
      try {
        // Reload user permissions (in case purchase has been done, but error due to later action (ex : google asks for second payment method, click, refuse -> error but subscirbed))
        permissions.value = await Glassfy.permissions();
        const perm = permissions.value?.all?.find(
          (p) => p.permissionId === "blue-pro"
        );
        const isValid = Boolean(perm && perm.isValid);
        if (isValid) {
          const { setShouldSubscribe } = useSubscribe();
          setShouldSubscribe(false);
          return { purchased: true };
        }
        return { purchased: false };
      } catch (e) {
        return { purchased: false };
      }
    }
  };

  const restore = async () => {
    try {
      permissions.value = await Glassfy.restorePurchases();
      const { setShouldSubscribe } = useSubscribe();
      setShouldSubscribe(false);
      return { restored: true };
    } catch (e) {
      return { restored: false };
    }
  };

  const checkSubscription = () => {
    // check internal unlimited permission
    const { user } = useStrapi();
    if (user.value.unlimited) {
      // some users have unlimited access (set in backoffice)
      if (user.value.expire_unlimited) {
        // check expiration date of unlimited access
        if (dayjs().isBefore(dayjs(user.value.expire_unlimited))) {
          return true;
        }
      } else {
        return true;
      }
    }
    // check real permissions
    const perm = permissions.value?.all?.find(
      (p) => p.permissionId === "blue-pro"
    );
    return Boolean(perm && perm.isValid);
  };

  const setDevice = (token) => {
    Glassfy.setDeviceToken({ token });
  };

  const connectUser = async () => {
    try {
      const { user } = useStrapi();
      if (user.value && user.value.id) {
        Glassfy.connectCustomSubscriber({
          subscriberId: "user_" + user.value.id,
        });
        Glassfy.setEmailUserProperty({ email: user.value.email });
      } else {
        Glassfy.connectCustomSubscriber(null);
      }
    } catch (e) {
      console.error("Error while connecting user to glassfy");
      console.error(e);
    }
  };

  return {
    offerings,
    permissions,
    no_glassfy,
    connectUser,
    setDevice,
    checkSubscription,
    initGlassfy,
    purchase,
    restore,
  };
};
