<template>
  <div>
    <div
      class="flex items-center px-6 py-6 text-white"
      :style="{ 'background-color': '#00000000' }"
    >
      <div
        v-if="!props.noButton"
        class="shrink-0 flex items-center cursor-pointer button-return transition-all duration-500 bg-nomoonnight text-sun"
        :style="{
          width: '42px',
          height: '42px',
          'border-radius': '24px',
        }"
        @click="back"
      >
        <ion-icon
          :icon="$icons.chevronBackOutline"
          :style="{ 'padding-left': '7px' }"
          class="text-2xl text-center font-bold"
        ></ion-icon>
      </div>
      <div
        class="grow text-xl font-semibold tracking-tighter"
        :class="{ 'pl-4': !props.noButton }"
      >
        <div v-if="props.label">
          {{ props.label }}
        </div>
        <div class="flex items-center gap-1 tracking-tight font-normal">
          <span
            v-if="subtitle"
            class="text-baseplus inline-block"
            :style="{ color: '#8CA0DF' }"
            >{{ subtitle }}</span
          >
          <span
            class="text-baseplus inline-block"
            :style="{ color: '#8CA0DF' }"
            v-else-if="
              !aquariums || !aquariums.list || aquariums.list.length === 0
            "
            >{{ $t("aquarium.none") }}</span
          >
          <div v-else>
            <span
              v-if="props.subtitleName"
              class="text-baseplus inline-block"
              :style="{ color: '#8CA0DF' }"
              >{{ currentAquarium?.name }}</span
            >
            <div class="flex items-center justify-start py-1 gap-2" v-else>
              <blue-dropdown
                :options="aquaList"
                :modelValue="current"
                @update:modelValue="(val) => select(val)"
                item-text="label"
                item-value="value"
                :placeholder="$t('aquarium.select')"
                interface="popover"
              />
              <ion-fab
                class="block relative"
                v-if="
                  currentAquarium &&
                  currentAquarium.config &&
                  !currentAquarium.config.disable_share
                "
                @click="clickShare"
              >
                <ion-fab-button size="small">
                  <ion-icon :icon="$icons.shareSocial"></ion-icon>
                </ion-fab-button>
              </ion-fab>
            </div>

            <div class="text-base text-bluegrey" v-if="currentAquarium">
              {{ currentAquarium.model }}
            </div>
          </div>
        </div>
      </div>
      <BubbleFish class="shrink-0" />
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, watch } from "vue";
import { IonFab, IonFabButton, useIonRouter } from "@ionic/vue";
import BubbleFish from "../BubbleFish.vue";
import { useAquariums } from "@/store/useAquariums.js";
import { useRoute } from "vue-router";
import { Share } from "@capacitor/share";
import { useI18n } from "vue-i18n";
import { useAnalytics } from "@/composable/useAnalytics";
import { useSubscribe } from "@/composable/useSubscribe";

const { t } = useI18n();
const aquariums = useAquariums();

const { event } = useAnalytics();

onMounted(() => {
  aquariums.loadAquariums();
});

const route = useRoute();
const currentId = computed(() =>
  route.params.id
    ? route.params.id == "new"
      ? "new"
      : Number(route.params.id)
    : null
);

watch(
  () => currentId.value,
  (n) => {
    if (n) {
      aquariums.setCurrent(n);
    }
  },
  { immediate: true }
);

const current = computed(() => "" + aquariums?.currentId);
const aquaList = computed(() => {
  const res = [{ value: -1, label: t("aquarium.new") }];
  aquariums?.list?.forEach((ent) => {
    res.push({
      value: "" + ent.id,
      label: ent.name,
    });
  });
  return res;
});

const currentAquarium = computed(() => {
  return aquariums?.list?.find((a) => a.id == currentId.value);
});

const props = defineProps({
  label: String,
  subtitle: String,
  noButton: Boolean,
  backCall: Function,
  backRoute: String,
  subtitleName: Boolean,
});

const clickShare = async () => {
  event("share");
  const perm = await Share.canShare();
  const opts = {
    title: currentAquarium.value?.name,
    url: "https://p.bluereef.app/" + currentAquarium.value?.token,
    dialogTitle: t("share.dialogtitle"),
  };
  if (perm.value) {
    await Share.share(opts);
  } else {
    console.log("Can not share", opts);
  }
};
const ionRouter = useIonRouter();
const back = () => {
  if (props.backCall != null) {
    props.backCall();
  } else if (props.backRoute != null) {
    ionRouter.navigate(props.backRoute, "backward", "replace");
  } else {
    ionRouter.back();
  }
};
const { controlPremiumAndBlock } = useSubscribe();
const select = (val) => {
  if (current.value != val) {
    // go to aqaurium page
    const id = Number(val);
    if (id == -1) {
      if (controlPremiumAndBlock()) {
        ionRouter.push("/aquarium/new/edit");
      }
    } else if (!isNaN(id)) {
      aquariums.setCurrent(val);
      if (route.path.indexOf("/measures") > 0) {
        ionRouter.push("/aquarium/" + val + "/measures");
      } else if (route.path.indexOf("/tasks") > 0) {
        ionRouter.push("/aquarium/" + val + "/tasks");
      } else if (route.path.indexOf("/edit") > 0) {
        ionRouter.push("/aquarium/" + val + "/edit");
      } else if (route.path.indexOf("/bailing") > 0) {
        ionRouter.push("/aquarium/" + val + "/bailing");
      } else if (route.path.indexOf("/lab") > 0) {
        ionRouter.push("/aquarium/" + val + "/lab");
      } else {
        ionRouter.push("/aquarium/" + val);
      }
    }
  }
};
</script>
<style>
.button-return:hover {
  color: white !important;
}
</style>
