import { defineStore } from "pinia";
import { useStorage } from "@/composable/useStorage";

export const useCategories = defineStore("categories", {
  state: () => ({
    list: [],
  }),

  getters: {
    categoriesSelect: (state) => {
      return state.list.map((ent) => {
        return {
          ...ent,
          code: ent.id,
          label: ent.name,
        };
      });
    },
  },
  actions: {
    async loadCategories() {
      const store = useStorage();
      this.list = store.coldata?.categories;

      /*if (this.updated) {
        try {
          const { client } = useStrapi();
          const res = await client("/collection-categories", {
            params: {
              populate: ["cover"],
            },
          });
          this.list = res.data.map((ent) => {
            const res = ent.attributes;
            res.id = ent.id;
            return res;
          });
          this.updated = false;
        } catch (e) {
          console.log(e);
        }
      }*/
    },
  },
});
