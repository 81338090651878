<template>
  <div>
    <div class="flex items-center" :class="{ 'justify-center': props.center }">
      <ion-toggle
        class="flex-none"
        :class="{ 'mr-2': !props.noLabel }"
        :modelValue="props.modelValue"
        @ionChange="updateCheckboxIon($event)"
        :disabled="disabled"
      ></ion-toggle>
      <ion-label
        class="cursor-pointer text-left"
        @click="updateCheckbox($event)"
      >
        {{ label }}
        <blue-tooltip :label="props.tooltip" v-if="props.tooltip" />
      </ion-label>
    </div>
    <div
      v-if="vErrors && vErrors.length > 0"
      class="text-red-500 text-xs text-left pl-4 pt-1"
    >
      <div class="input-errors" v-for="error of vErrors" :key="error.$uid">
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
import { IonLabel, IonToggle } from "@ionic/vue";

const props = defineProps({
  modelValue: Boolean,
  label: String,
  name: String,
  validator: Object,
  disabled: Boolean,
  noLabel: Boolean,
  tooltip: String,
  center: Boolean,
});

const touched = ref(false);
const markTouched = () => {
  if (fvalid.value.$touch) {
    fvalid.value.$touch();
  }
  touched.value = true;
};
const emit = defineEmits(["update:modelValue"]);
const updateCheckbox = ($event) => {
  if (props.disabled) return;
  markTouched();
  emit("update:modelValue", !props.modelValue);
  $event.stopPropagation();
  $event.preventDefault();
};
const updateCheckboxIon = ($event) => {
  markTouched();
  emit("update:modelValue", $event.detail.checked);
  $event.stopPropagation();
  $event.preventDefault();
};

const fvalid = computed(
  () => (props.validator && props.validator[props.name]) || {}
);
const vErrors = computed(() => {
  return fvalid.value.$errors;
});
</script>
