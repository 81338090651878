import { createApp } from "vue";
import { createPinia } from 'pinia'

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

import { createI18n, useI18n } from "vue-i18n";

/* Theme variables */
import "./theme/variables.css";
import "./assets/styles/tailwind.css";
import "./assets/styles/main.css";

// eslint-disable-next-line
// @ts-ignore
import { initStorage, useStorage, SUPPORTED_LANGS } from "./composable/useStorage";
import { Device } from "@capacitor/device";

// eslint-disable-next-line
// @ts-ignore
import { useStrapi } from "./composable/useStrapi";

// listen events linked to push notifications
import { usePushNotifications } from "./composable/usePushNotifications"

const isPortal = process.env.VUE_APP_PORTAL != null

if (!isPortal) {
  const { addPushNotificationsListeners }  = usePushNotifications();
  try {
    addPushNotificationsListeners();
  } catch (e) {
    console.error("No listeners registered for push -> this will result in impossibility to handle incoming notifications");
  }
}

const pinia = createPinia()
let app  = null;
if (!isPortal) {
  app = createApp((await import("./App.vue")).default).use(IonicVue).use(pinia);
} else {
  app = createApp((await import("./PortalApp.vue")).default).use(IonicVue).use(pinia);
}

// ionic components
import {
  IonPage,
  IonModal,
  IonContent,
  IonButton,
  IonPopover,
  IonIcon
} from "@ionic/vue";
app.component("IonPage", IonPage);
app.component("IonModal", IonModal);
app.component("IonContent", IonContent);
app.component("IonButton", IonButton);
app.component("IonPopover", IonPopover);
app.component("IonIcon", IonIcon);

/* Local components */
import BlueContent from "./components/design/BlueContent.vue"
import BlueLoader from "./components/BlueLoader.vue"
import BlueSegment from "./components/form/BlueSegment.vue"
import BlueChoice from "./components/form/BlueChoice.vue"
import BlueDropdown from "./components/form/BlueDropdown.vue"
import BlueToggle from "./components/form/BlueToggle.vue"
import BlueAlert from "./components/BlueAlert.vue"
import BlueLink from "./components/BlueLink.vue"
import BluePageLink from "./components/BluePageLink.vue"
import BlueDrawer from "./components/BlueDrawer.vue"
import BlueAvatar from "./components/BlueAvatar.vue"
import BlueMarkdown from "./components/BlueMarkdown.vue"
import BlueTitle from "./components/BlueTitle.vue"
import BlueTitleToggle from "./components/BlueTitleToggle.vue"
import BlueFilterButton from "./components/BlueFilterButton.vue"
import BlueChip from "./components/BlueChip.vue"
import BlueTooltip from "./components/BlueTooltip.vue"
import BlueFabButton from "./components/BlueFabButton.vue"
import BlueLinksHandler from "./components/BlueLinksHandler.js"
import BlueSliderLightBox from "./components/BlueSliderLightBox.vue"
import BlueHScroll from "./components/BlueHScroll.vue"
app.component("BlueContent", BlueContent);
app.component("BlueSegment", BlueSegment);
app.component("BlueChoice", BlueChoice);
app.component("BlueDropdown", BlueDropdown);
app.component("BlueToggle", BlueToggle);
app.component("BlueLoader", BlueLoader);
app.component("BlueAlert", BlueAlert);
app.component("BlueLink", BlueLink);
app.component("BluePageLink", BluePageLink);
app.component("BlueDrawer", BlueDrawer);
app.component("BlueAvatar", BlueAvatar);
app.component("BlueMarkdown", BlueMarkdown);
app.component("BlueTitle", BlueTitle);
app.component("BlueTitleToggle", BlueTitleToggle);
app.component("BlueFilterButton", BlueFilterButton);
app.component("BlueChip", BlueChip);
app.component("BlueTooltip", BlueTooltip);
app.component("BlueFabButton", BlueFabButton);
app.directive("BlueLinks", BlueLinksHandler);
app.component("BlueSliderLightBox", BlueSliderLightBox);
app.component("BlueHScroll", BlueHScroll);

import 'vue-tel-input/dist/vue-tel-input.css';
if (!isPortal) {
  app.component("BlueCountries", (await import("./components/form/BlueCountries.vue")).default);
  app.component("BlueAddressPicker", (await import("./components/form/BlueAddressPicker.vue")).default);
  app.component("BlueAddress", (await import("./components/form/BlueAddress.vue")).default);
  app.component("BlueRegion", (await import("./components/form/BlueRegion.vue")).default);
  app.component("BluePictures", (await import("./components/BluePictures.vue")).default);
  app.component("BlueUpload", (await import("./components/form/BlueUpload.vue")).default);
  app.component("BlueCheckbox", (await import("./components/form/BlueCheckbox.vue")).default);
  app.component("BlueInput", (await import("./components/form/BlueInput.vue")).default);
  app.component("BluePhone", (await import("./components/form/BluePhone.vue")).default);
  app.component("BlueStepper", (await import("./components/form/BlueStepper.vue")).default);
  app.component("BlueDate", (await import("./components/form/BlueDate.vue")).default);
  app.component("BlueDateTime", (await import("./components/form/BlueDateTime.vue")).default);
  app.component("BlueError", (await import("./components/form/BlueError.vue")).default);
  app.component("BlueRefresher", (await import("./components/BlueRefresher.vue")).default);
  app.component("BlueSliderLightBoxNoModal", (await import("./components/BlueSliderLightBoxNoModal.vue")).default);
  app.component("BlueGalleryLightBoxNoModal", (await import("./components/BlueGalleryLightBoxNoModal.vue")).default);
  app.component("AdOptionModal", (await import("./components/notification/AdOptionModal.vue")).default);

  app.component("DraggableList", (await import('vue-draggable-next')).VueDraggableNext)
  // phone
  app.component("VueTelInput", (await import('vue-tel-input')).VueTelInput);
}

/** Icons  */
import { personCircleOutline, lockClosedOutline, mailOutline, checkmarkCircle,checkmark, homeOutline, fishOutline,  bookOutline, newspaperOutline, storefrontOutline, chevronBackOutline, chevronDownOutline,caretDown, heart, heartOutline, closeOutline,ban, closeCircle,  closeCircleOutline, addOutline, menuOutline, moveOutline, reorderFourOutline, remove, removeOutline, trendingUpOutline, trendingDownOutline, reorderTwoOutline, warningOutline, warning, notifications, ticket, mail, shareSocial, pencilOutline, pencilSharp, repeatOutline, chevronForwardOutline, caretBackOutline, caretForwardOutline, options, send, chatbubbleEllipses, ellipsisVertical, image, informationCircle, alertCircle,alert, eye, calendar, skull, logoApple, trash, school, images } from 'ionicons/icons';
app.config.globalProperties.$icons = {
  personCircleOutline,
  lockClosedOutline,
  mailOutline,
  checkmarkCircle,checkmark, homeOutline, fishOutline,  bookOutline, newspaperOutline, storefrontOutline, chevronBackOutline,chevronDownOutline,caretDown, heart, heartOutline, closeOutline, ban, closeCircle, closeCircleOutline, addOutline,menuOutline, moveOutline, reorderFourOutline, remove, removeOutline, trendingUpOutline, trendingDownOutline, reorderTwoOutline, warningOutline, warning,  notifications, ticket, mail, shareSocial, pencilOutline, pencilSharp, repeatOutline, chevronForwardOutline, caretBackOutline, caretForwardOutline, options, send, chatbubbleEllipses, ellipsisVertical, image, informationCircle, alertCircle,alert, eye, calendar, skull, logoApple, trash, school, images
}

// router
const init = async () => {
  // initialise storage
  await initStorage()

  // check locale
  const store = useStorage();
  if (!store.locale) {
    // detect language
    const langdevice = await Device.getLanguageCode();

    let langToSet = null
    if (langdevice.value && SUPPORTED_LANGS.includes(langdevice.value)) {
      langToSet = langdevice.value
    } else {
      langToSet = "en"
    }
    await store.setLocale(langToSet)
  }

  // initialize strapi 
  const { initToken } = useStrapi()
  await initToken()
  
  app.mount("#app");
};

if (!isPortal) {
  const router = await import("./router")
  app.use(router.default)
  router.default.isReady().then(() => init())
} else {
  const portalRouter = await import("./router/portal")
  app.use(portalRouter.default)
  portalRouter.default.isReady().then(() => init())
}

// i18n
// eslint-disable-next-line
// @ts-ignore
import { languages } from './locales/index.js'
const messages = Object.assign(languages)
const i18n = createI18n({
  legacy: false,
  locales: SUPPORTED_LANGS,
  fallbackLocale: 'en',
  allowComposition: true, // you need to specify that!
  messages
});

app.use(i18n);

app.config.globalProperties.$tdb = (obj:any) => {
  const { locale } = useI18n()
  return (obj && (obj[locale.value] || obj.en || obj.fr)) || '' // ouais c'est un peu nimp
}

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
// Dayjs locales to load
require('dayjs/locale/fr')
require('dayjs/locale/th')
//require('dayjs/locale/de') 
//require('dayjs/locale/es') 
//require('dayjs/locale/it') 
dayjs.extend(localizedFormat)

import localeData from 'dayjs/plugin/localeData'
dayjs.extend(localeData)

import isoWeek  from 'dayjs/plugin/isoWeek'
dayjs.extend(isoWeek)

import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

app.config.globalProperties.$formatDate = (obj:any, presentation:string) => {
  const { locale } = useI18n()
  dayjs.locale(locale.value)
  if (presentation == null || presentation == "date") {
    return dayjs.utc(obj).local().format('LL')
  } else if (presentation == null || presentation == "small-date") {
    return dayjs.utc(obj).local().format('D MMM')
  } else if (["date-time", "time-date"].includes(presentation)) {
    return dayjs.utc(obj).local().format('LL HH:mm')
  } else if (["time"].includes(presentation)) {
    return dayjs.utc(obj).local().format('HH:mm')
  }
}

app.config.globalProperties.$formatPrice = (obj:any) => {
  const { locale } = useI18n();
  return Intl.NumberFormat(locale.value, {
    style: "currency",
    currency: obj?.currency || 'EUR',
  }).format(obj?.price || 0);
}

import { useUnits } from "@/composable/useUnits.js";
const {
  formatUnit,
  getUnitValue,
  saveValue,
  getUnitAbbrForMeasure,
  getUnitAbbr,
  tools,
} = useUnits();
/** Util function to display units */
app.config.globalProperties.$dVol = (value:any) => {
  return formatUnit("volume", value)
}
app.config.globalProperties.$dMl = (value:any, decimals:any) => {
  return formatUnit("ml", value, decimals)
}
app.config.globalProperties.$dG = (value:any) => {
  return formatUnit("g", value)
}
app.config.globalProperties.$dvUnit = (unit:any, value:any) => {
  return getUnitValue(unit, value)
}
app.config.globalProperties.$svUnit = (unit:any, value:any) => {
  return saveValue(unit, value)
}
app.config.globalProperties.$dvVol = (value:any) => {
  return getUnitValue("volume", value)
}
app.config.globalProperties.$dvMl = (value:any, decimals:any) => {
  return getUnitValue("ml", value, decimals)
}
app.config.globalProperties.$dvG = (value:any) => {
  return getUnitValue("g", value)
}
app.config.globalProperties.$unit = (unit:any) => {
  return getUnitAbbr(unit)
}

import { SplashScreen } from '@capacitor/splash-screen';
await SplashScreen.show({
  autoHide: false,
});

import mdit from 'markdown-it'
import mark from "markdown-it-mark";

const markdownit = new mdit({
  html: true,
  xhtmlOut: false,
  breaks: false,
  langPrefix: 'language-',
  linkify: true,
  typographer: true,
  quotes: '“”‘’',
}).use(mark);

markdownit.linkify.set({ fuzzyEmail: false })

app.config.globalProperties.$mdit = markdownit
