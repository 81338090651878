import { useI18n } from "vue-i18n";

export const useTdb = () => {
  const { locale } = useI18n();
  const tdb = (obj) => {
    return (obj && (obj[locale.value] || obj.en || obj.fr)) || "not defined"; // ouais c'est un peu nimp
  };

  return {
    tdb,
  };
};
