import { Browser } from "@capacitor/browser";

/** This directive finds all a links inside an element and replace links with native browser opening */
export default {
  mounted(el) {
    el.clickLink = async function (event) {
      const url = event.target.href;

      event.preventDefault();
      event.stopPropagation();

      await Browser.open({ url });
    };
    el.links = el.querySelectorAll("a");
    if (el.links) {
      el.links.forEach((a) => {
        a.addEventListener("click", el.clickLink);
      });
    }
  },
  unmounted(el) {
    // Remove the event listener when the bound element is unmounted
    if (el.links) {
      el.links.forEach((a) => {
        a.removeEventListener("click", el.clickOutsideEvent);
      });
    }
  },
};
