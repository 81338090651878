<template>
  <scale-loader
    :loading="props.loading"
    :color="props.color"
    :height="props.small ? '17px' : props.xSmall ? '14px' : '35px'"
    :width="props.small ? '2px' : props.xSmall ? '1px' : undefined"
  ></scale-loader>
</template>
<script setup>
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
const props = defineProps({
  loading: Boolean,
  small: Boolean,
  xSmall: Boolean,
  color: {
    type: String,
    default: "#FFEF4B",
  },
});
</script>
