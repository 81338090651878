<template>
  <ion-content :fullscreen="true">
    <div
      class="page-container"
      :class="{ 'h-full': props.hFull, 'no-padding': props.noPadding }"
    >
      <slot></slot>
    </div>
  </ion-content>
</template>
<script setup>
const props = defineProps({
  hFull: Boolean,
  noPadding: Boolean,
});
</script>
<style>
.page-container {
  max-width: 1024px;
  margin: auto;
  padding-top: 40px;
}
.page-container.no-padding {
  padding-top: 0px;
}
</style>
