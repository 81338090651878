import { defineStore } from "pinia";
import { useStorage } from "@/composable/useStorage";

export const useSettings = defineStore("settings", {
  state: () => ({
    settings: {},
  }),

  actions: {
    async loadSettings() {
      const store = useStorage();
      this.settings = {};
      store.coldata?.settings?.forEach((s) => {
        this.settings[s.key] = s.value;
      });
    },
  },
});
