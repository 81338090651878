<template>
  <div>
    <ion-item lines="none" :disabled="props.disabled">
      <ion-select
        :toggle-icon="$icons.caretDown"
        :modelValue="props.modelValue == null ? '__null__' : props.modelValue"
        :label="props.label"
        :name="props.name"
        :placeholder="props.placeholder"
        :interface="props.interface"
        @ionChange="update($event)"
        class="w-full"
        :multiple="props.multiple"
      >
        <template v-for="ent of optionsEnh" :key="ent.value">
          <ion-select-option
            :value="ent.value"
            :class="{ 'font-bold': ent.bold }"
          >
            {{ i18n && !ent.no_i18n ? $tdb(ent.label) : ent.label }}
          </ion-select-option>
        </template>
      </ion-select>
    </ion-item>
    <div
      v-if="vErrors && vErrors.length > 0"
      class="text-red-500 text-xs text-left pl-4 pt-1"
    >
      <div class="input-errors" v-for="error of vErrors" :key="error.$uid">
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { IonItem, IonSelectOption, IonSelect } from "@ionic/vue";

const props = defineProps({
  modelValue: Array || String,
  options: Array,
  placeholder: String,
  label: String,
  name: String,
  validator: Object,
  itemValue: String,
  itemText: String,
  i18n: Boolean,
  interface: String,
  multiple: Boolean,
  disabled: Boolean,
});

const emit = defineEmits(["update:modelValue"]);
const update = ($event) => {
  emit(
    "update:modelValue",
    $event.detail.value === "__null__" ? null : $event.detail.value
  );
};

const optionsEnh = computed(() => {
  return props.options?.map((opt) => {
    if (opt) {
      let val;
      if (props.itemValue) {
        if (opt[props.itemValue] == null) {
          val = "__null__";
        } else {
          val = "" + opt[props.itemValue];
        }
      } else {
        if (opt.value == null) {
          val = "__null__";
        } else {
          val = "" + opt.value;
        }
      }
      return {
        value: val,
        label: props.itemText ? opt[props.itemText] : opt.label,
        no_i18n: opt.no_i18n,
        bold: opt.bold,
      };
    } else {
      return { value: null };
    }
  });
});

const fvalid = computed(() => props.validator && props.validator[props.name]);
const vErrors = computed(() => {
  return fvalid.value?.$errors;
});
</script>
<style>
ion-radio {
  --color: transparent;
  --color-checked: #16db8f;
}
ion-radio.radio-checked.md::part(container) {
  border-color: transparent;
}
ion-radio.md::part(container) {
  background-color: #0e153b;
}
</style>
