import { reactive } from "vue";

/**
 * This composable helps rendering blocages when router navigates if things have to be done before
 * savers are the different intercepters, it should be declared on the router as meta.save
 * pages need to call initSave with an async callback, navigation will proceed if the callback returns true, blocked if false
 */
const subscribeParams = reactive({});
export const useSubscribe = () => {
  const setShouldSubscribe = (should) => {
    subscribeParams.shouldSubscribe = should;
  };
  const setCallback = (cb) => {
    subscribeParams.callback = cb;
  };
  const controlPremiumAndBlock = (feature) => {
    if (subscribeParams.shouldSubscribe) {
      if (typeof subscribeParams.callback === "function") {
        // user should subscribe (trial ended or subscription expired) -> display popup
        subscribeParams.callback(feature);
        return false;
      }
    }
    return true;
  };
  return {
    subscribeParams,
    setShouldSubscribe,
    setCallback,
    controlPremiumAndBlock,
  };
};
